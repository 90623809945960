import { FunctionField, ReferenceField, TextField } from "react-admin";

export const ReferenceMissionField = (props: any) => (
  <ReferenceField source="MissionId" reference="Mission" {...props}>
    <TextField source="text" />
  </ReferenceField>
);

export const ReferenceMissionAchievedCountField = (props: any) => (
  <ReferenceField
    source="MissionAchievedCountId"
    reference="MissionAchievedCount"
    {...props}
  >
    <TextField source="text" />
  </ReferenceField>
);

export const ReferenceItemField = (props: any) => (
  <ReferenceField source="ItemId" reference="Item" {...props}>
    <TextField source="name" />
  </ReferenceField>
);

export const ReferenceCharacterCardField = (props: any) => (
  <ReferenceField source="CharacterCardId" reference="CharacterCard" {...props}>
    <FunctionField render={(r: any) => `${r.card_name} ${r.card_name_ruby}`} />
  </ReferenceField>
);

export const ReferenceEquipmentCardField = (props: any) => (
  <ReferenceField source="EquipmentCardId" reference="EquipmentCard" {...props}>
    <FunctionField render={(r: any) => `${r.name} ${r.card_name}`} />
  </ReferenceField>
);

export const ReferenceShopMerchandiseField = (props: any) => (
  <ReferenceField source="ShopMerchandiseId" reference="ShopMerchandise">
    <TextField source="name" />
  </ReferenceField>
);
