import { useNavigate } from "react-router-dom";
import {
  FunctionField,
  NumberInput,
  ReferenceField,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { CommonLogUserList, CommonLogUserFilter, NumField } from "../Common";
import { Typography } from "@mui/material";
import { Platforms } from "../../common/Platform";
import { StoneTypes } from "../../common/StoneType";

const filters = CommonLogUserFilter([
  <TextInput source="UserStoneDetailId" label="UserStoneDetailId" />,
  <NumberInput source="Count" label="獲得数" />,
  <NumberInput source="BeforeCount" label="獲得数Before" />,
  <NumberInput source="AfterCount" label="獲得数After" />,
]);

const LogUserStoneUserStoneDetailIdField = (props: any) => {
  const record = useRecordContext();
  const navigate = useNavigate();

  const handleClick = () => {
    const filter = JSON.stringify({
      Id: record["UserStoneDetailId"],
      UserId: record["UserId"],
    });
    navigate(`/UserStoneDetail?filter=${encodeURIComponent(filter)}`);
  };

  return (
    <Typography
      component="span"
      onClick={handleClick}
      style={{
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
      }}
    >
      <TextField source="UserStoneDetailId" />
    </Typography>
  );
};

export const LogUserStoneList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <LogUserStoneUserStoneDetailIdField label="UserStoneDetailId" />
    <ReferenceField
      source="UserStoneDetailId"
      reference="UserStoneDetail"
      label="ジュエル種別 / プラットフォーム"
    >
      <FunctionField
        render={(r: any) =>
          `${r.StoneType}: ${StoneTypes[r.StoneType]} ${r.Platform}: ${
            Platforms[r.Platform]
          }`
        }
      />
    </ReferenceField>
    <NumField source="Count" label="獲得数" />
    <NumField source="BeforeCount" label="総数Before" />
    <NumField source="AfterCount" label="総数After" />
  </CommonLogUserList>
);
