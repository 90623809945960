export enum Role {
  Admin = "admin",
  Member = "member",
  Reader = "reader",
  Planner = "planner",
}

export const roleNames = [
  { id: Role.Admin, name: "管理者" },
  { id: Role.Member, name: "メンバー" },
  { id: Role.Reader, name: "閲覧者" },
  { id: Role.Planner, name: "プランナー" },
];

export const rolesAdmin = [Role.Admin];
export const rolesMember = [Role.Admin, Role.Member];
export const rolesPlanner = [Role.Admin, Role.Member, Role.Planner];
export const rolesReader = [Role.Admin, Role.Member, Role.Planner, Role.Reader];
