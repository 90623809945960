import { NumberInput } from "react-admin";
import {
  CommonLogUserFilter,
  CommonLogUserList,
  NumField,
  ReferenceMissionAchievedCountField,
} from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="MissionAchievedCountId" label="達成数ミッションID" />,
]);

export const LogUserMissionAchievedCountRewardList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="MissionAchievedCountId" label="達成数ミッションID" />
    <ReferenceMissionAchievedCountField label="ミッション名" />
  </CommonLogUserList>
);
