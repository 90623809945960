import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField, YMDHMSDateField } from "../Common";

const userStoneStaminaRecoveryFilters = [
  <NumberInput source="Step" label="ステップ" />,
];

export const UserStoneStaminaRecoveryList = (props: any) => (
  <CommonList {...props} addFilters={userStoneStaminaRecoveryFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="Step" label="ステップ" />
      <YMDHMSDateField source="LastRecoveredAt" label="最終回復日時" />
    </Datagrid>
  </CommonList>
);
