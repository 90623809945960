import { NumberInput } from "react-admin";
import {
  CommonLogUserFilter,
  CommonLogUserList,
  NumField,
  YMDHMSDateField,
  DateTimeWithSecInput,
} from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput
    source="QuestBattleDifficultyId"
    label="クエストバトル難易度ID"
  />,
  <NumberInput source="EnemyGroupId_1" label="敵グループID 1" />,
  <NumberInput source="EnemyGroupId_2" label="敵グループID 2" />,
  <NumberInput source="EnemyGroupId_3" label="敵グループID 3" />,
  <NumberInput source="PartyNumber" label="パーティ番号" />,
  <NumberInput source="TotalPower" label="戦力" />,
  <DateTimeWithSecInput source="BattleExpiredAt_from" label="バトル期限From" />,
  <DateTimeWithSecInput
    source="BattleExpiredAt_after"
    label="バトル期限After"
  />,
]);

// TODO JSON field
export const LogUserBattleStartList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />
    <NumField source="EnemyGroupId_1" label="敵グループID 1" />
    <NumField source="EnemyGroupId_2" label="敵グループID 2" />
    <NumField source="EnemyGroupId_3" label="敵グループID 3" />
    <NumField source="PartyNumber" label="パーティ番号" />
    <NumField source="TotalPower" label="戦力" />
    <YMDHMSDateField source="BattleExpiredAt" label="バトル期限" />
  </CommonLogUserList>
);
