import { useState, useEffect, useLayoutEffect } from "react";
import {
  Toolbar,
  SaveButton,
  Create,
  Edit,
  EditButton,
  Datagrid,
  SimpleForm,
  TextField,
  TextInput,
  DeleteWithConfirmButton,
  useDataProvider,
  useNotify,
  useGetOne,
  useRecordContext,
  ImageInput,
  ImageField,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { EditTitle } from "../Common";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import {
  HTMLPreviewRawText,
  AnnouncementBuilderComponent,
} from "./htmlComponent";
import { AnnouncementTemplateLoader } from "./templateLoader";
import {
  CommonList,
  DateTimeWithSecInput,
  YMDHMSDateField,
  NumField,
} from "../Common";

export const AnnouncementList = (props: any) => (
  <CommonList {...props} filters={null}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="Id" />
      <TextField source="Title" label="Title" />
      <TextField source="Path" label="Path" />
      <YMDHMSDateField source="OpenAt" label="OpenAt" />
      <YMDHMSDateField source="CloseAt" label="CloseAt" />
      <TextField source="Category" label="Category" />
      <TextField source="Tag" label="Tag" />
      <TextField source="ThumbnailUrl" label="ThumbnailURL" />
      <NumField source="Priority" label="Priority" />
      <EditButton />
    </Datagrid>
  </CommonList>
);

const readAsBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target?.result;
      if (typeof result !== "string")
        throw TypeError("Reader did not return string.");
      resolve(result.split("base64,")[1]);
    };

    reader.onerror = (e: ProgressEvent<FileReader>) => {
      reject(e.target?.error);
    };

    reader.readAsDataURL(file);
  });

const HTMLCreateToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { getValues } = useFormContext();

  const thumbnail = getValues("Thumbnail");
  const images = getValues("Images");

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      var img = [];
      if (images && images.length !== 0) {
        img = await Promise.all(
          images.map(async (img: { rawFile: File }) => {
            return {
              data: await readAsBase64(img.rawFile),
              file_name: img.rawFile.name,
            };
          })
        );
      }

      return dataProvider.create("Announcement", {
        data: {
          id: getValues("Id"),
          title: getValues("Title"),
          body: getValues("Body"),
          html: docType + html,
          open_at: getValues("OpenAt"),
          close_at: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
          banner: getValues("Banner"),
          thumbnail: {
            data: await readAsBase64(thumbnail.rawFile),
            file_name: thumbnail.rawFile.name,
          },
          images: img,
          priority: getValues("Priority"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton
        label="作成"
        onClick={() => mutate()}
        disabled={isLoading || !thumbnail || !thumbnail.rawFile}
      />
    </Toolbar>
  );
};

const announcementTemplateBucketPath =
  process.env.REACT_APP_ANNOUNCEMENT_TEMPLATE_BUCKET_URL;

export const AnnouncementCreate = () => {
  const [html, setHTML] = useState("");

  useLayoutEffect(() => {
    (async () => {
      const h = await AnnouncementTemplateLoader.load(
        `${announcementTemplateBucketPath!}/index.html`
      );
      const replaced = AnnouncementTemplateLoader.replaceURL(
        h,
        announcementTemplateBucketPath!
      );
      setHTML(replaced);
    })();
  }, []);

  return (
    <Create>
      <SimpleForm toolbar={<HTMLCreateToolbar />}>
        <AnnouncementBuilderComponent />
        <TextInput
          source="Id"
          helperText="指定しない場合はランダムな値が設定"
        />
        <TextInput source="Title" isRequired />
        <TextInput source="Body" isRequired multiline fullWidth />
        <ImageInput source="Thumbnail" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="Banner" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="Images" label="画像">
          <SimpleFormIterator inline>
            <ImageInput source="" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="Category" isRequired />
        <TextInput source="Tag" isRequired />
        <DateTimeWithSecInput source="OpenAt" isRequired />
        <DateTimeWithSecInput source="CloseAt" isRequired />
        <NumberInput source="Priority" isRequired />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Create>
  );
};

const AnnouncementUpdateToolbar = (props: any) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { data: previous, isLoading: isGetOneLoading } = useGetOne(
    "Announcement",
    { id: record.id }
  );
  const { getValues } = useFormContext();
  const thumbnail = getValues("Thumbnail");
  const images = getValues("Images");

  useEffect(() => {
    if (isGetOneLoading) return;
    (async () => {
      const h = await AnnouncementTemplateLoader.load(previous.Path);
      props.setHTML(h);
    })();
  }, [isGetOneLoading]);

  const { mutate, isLoading } = useMutation(
    ["update"],
    async () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      var img = [];
      if (images && images.length !== 0) {
        img = await Promise.all(
          images.map(async (img: { rawFile: File }) => {
            return {
              data: await readAsBase64(img.rawFile),
              file_name: img.rawFile.name,
            };
          })
        );
      }

      var thumbnailData = {};

      if (thumbnail && thumbnail.rawFile) {
        thumbnailData = {
          data: await readAsBase64(thumbnail.rawFile),
          file_name: thumbnail.rawFile.name,
        };
      }

      return dataProvider.update("Announcement", {
        id: record.id,
        previousData: {
          title: previous.title,
          body: previous.body,
          openAt: previous.openAt,
          closeAt: previous.closeAt,
          category: previous.category,
          tag: previous.tag,
          priority: previous.priority,
        },
        data: {
          title: getValues("Title"),
          html: docType + html,
          body: getValues("Body"),
          openAt: getValues("OpenAt"),
          closeAt: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
          thumbnail: thumbnailData,
          images: img,
          priority: getValues("Priority"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton
        label="作成"
        onClick={() => mutate()}
        disabled={isGetOneLoading || isLoading}
      />
      <DeleteWithConfirmButton
        confirmContent="You will not be able to recover this record. Are you sure?"
        translateOptions={{ name: record.id }}
      />
    </Toolbar>
  );
};

export const AnnouncementEdit = (props: any) => {
  const [html, setHTML] = useState("");

  return (
    <Edit {...props} title={<EditTitle name="Announcement" />}>
      <SimpleForm toolbar={<AnnouncementUpdateToolbar setHTML={setHTML} />}>
        <AnnouncementBuilderComponent />
        <TextInput source="Title" />
        <TextInput source="Body" multiline fullWidth />
        <ImageInput source="Thumbnail" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="Banner" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="Images" label="画像">
          <SimpleFormIterator inline>
            <ImageInput source="" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <DateTimeWithSecInput source="OpenAt" />
        <DateTimeWithSecInput source="CloseAt" />
        <TextInput source="Category" />
        <TextInput source="Tag" />
        <NumberInput source="Priority" />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Edit>
  );
};
