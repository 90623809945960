import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userExternalBilledStoneFilters = [
  <NumberInput source="Count" label="所持数" />,
];

export const UserExternalBilledStoneList = (props: any) => (
  <CommonList {...props} addFilters={userExternalBilledStoneFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="Count" label="所持数" />
    </Datagrid>
  </CommonList>
);
