import { NumberInput, TextInput } from "react-admin";
import {
  CommonLogUserFilter,
  CommonLogUserList,
  NumField,
  YMDHMSDateField,
  DateTimeWithSecInput,
} from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="TowerStageId" label="タワーステージID" />,
  <NumberInput source="EnemyGroupId_1" label="敵グループID 1" />,
  <NumberInput source="EnemyGroupId_2" label="敵グループID 2" />,
  <NumberInput source="EnemyGroupId_3" label="敵グループID 3" />,
  <NumberInput source="PartyNumber" label="パーティ番号" />,
  <NumberInput source="TotalPower" label="総戦力" />,
  <TextInput source="Party" label="パーティ" />,
  <DateTimeWithSecInput
    source="BattleExpiredAt_from"
    label="バトル有効期限From"
  />,
  <DateTimeWithSecInput
    source="BattleExpiredAt_to"
    label="バトル有効期限From"
  />,
]);

// TODO JSON field
export const LogUserTowerBattleStartList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="TowerStageId" label="タワーステージID" />
    <NumField source="EnemyGroupId_1" label="敵グループID 1" />
    <NumField source="EnemyGroupId_2" label="敵グループID 2" />
    <NumField source="EnemyGroupId_3" label="敵グループID 3" />
    <NumField source="PartyNumber" label="パーティ番号" />
    <NumField source="TotalPower" label="総戦力" />
    <YMDHMSDateField source="BattleExpiredAt" label="バトル有効期限" />
  </CommonLogUserList>
);
