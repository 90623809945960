import { NumberInput, TextInput, TextField } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput
    source="QuestBattleDifficultyId"
    label="クエストバトル難易度ID"
  />,
  <TextInput source="BattleResult" label="バトル結果" />,
]);

// TODO JSON field
export const LogUserBattleFinishList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />
    <TextField source="BattleResult" label="バトル結果" />
  </CommonLogUserList>
);
