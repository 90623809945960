import React, { createContext, useContext, useState } from "react";

export const ApiEndpointContext = createContext({
  apiEndpoint: 0,
  setApiEndpoint: (endpoint: number) => {},
});

export const useApiEndpoint = () => useContext(ApiEndpointContext);

export const ApiEndpointProvider = ({ children }: any) => {
  const [apiEndpoint, setApiEndpoint] = useState(0);

  return (
    <ApiEndpointContext.Provider value={{ apiEndpoint, setApiEndpoint }}>
      {children}
    </ApiEndpointContext.Provider>
  );
};
