import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userStoneFilters = [<NumberInput source="Count" label="所持数" />];

export const UserStoneList = (props: any) => (
  <CommonList {...props} addFilters={userStoneFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="Count" label="所持数" />
    </Datagrid>
  </CommonList>
);
