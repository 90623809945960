import { Datagrid, TextField } from "react-admin";
import { CommonList, YMDHMDateField } from "../Common";

export const UserDeviceHistoryList = (props: any) => (
  <CommonList {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="DeviceModel" label="機種名" />
      <TextField source="OperatingSystem" label="OS" />
      <YMDHMDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
