import { Card } from "@mui/material";
import {
  Datagrid,
  ListContextProvider,
  RecordContextProvider,
  SimpleShowLayout,
  TextField,
  useList,
} from "react-admin";

export const UserList = (props: any) => {
  const listContext = useList({ data: props.data });
  const summary = { count: props.data.length }
  return (
    <div>
      <Card>
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={false}>
            <TextField label="UserId" source="UserId" />
          </Datagrid>
        </ListContextProvider>
      </Card>
      <Card>
        <RecordContextProvider value={summary}>
          <SimpleShowLayout>
            <TextField label="件数" source="count" />
          </SimpleShowLayout>
        </RecordContextProvider>
      </Card>
    </div>
  );
};
