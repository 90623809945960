import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userPartyEquipmentCardFilters = [
  <NumberInput source="PartyNumber" label="パーティーNO" />,
  <NumberInput source="CharacterCardSlotNumber" label="MCカードスロットNO" />,
  <NumberInput
    source="EquipmentCardSlotNumber"
    label="メモリーカードスロットNO"
  />,
  <NumberInput source="EquipmentCardId" label="EquipmentCardId" />,
  <NumberInput source="UserEquipmentCardNumber" label="ユーザーMCカードNO" />,
];

export const UserPartyEquipmentCardList = (props: any) => (
  <CommonList {...props} addFilters={userPartyEquipmentCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="PartyNumber" label="パーティーNO" />
      <NumField source="CharacterCardSlotNumber" label="MCカードスロットNO" />
      <NumField
        source="EquipmentCardSlotNumber"
        label="メモリーカードスロットNO"
      />
      <NumField source="EquipmentCardId" label="EquipmentCardId" />
      <NumField source="UserEquipmentCardNumber" label="ユーザーMCカードNO" />
    </Datagrid>
  </CommonList>
);
