import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  SelectField,
} from "react-admin";
import {
  CommonList,
  DateTimeWithSecInput,
  EditToolbar,
  EditTitle,
  YMDHMSDateField,
  NumField,
} from "../Common";
import { ContentTypes } from "common/ContentType";

export const GlobalPresentList = (props: any) => (
  <CommonList
    {...props}
    filters={null}
    sort={{ field: "OpenAt", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="PresentId" label="ID" />
      <SelectField
        source="ContentType"
        label="コンテンツタイプ"
        choices={ContentTypes}
      />
      <NumField source="ContentId" label="コンテンツID" />
      <NumField source="ContentNum" label="個数" />
      <YMDHMSDateField source="OpenAt" label="付与開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="受取期限" />
      <TextField source="CustomMessage" label="カスタムメッセージ" />
      <YMDHMSDateField source="UserCreatedAtBefore" label="ユーザー作成日" />
      <YMDHMSDateField
        source="TutorialCompletedAtBefore"
        label="チュートリアル完了日"
      />
      <EditButton />
    </Datagrid>
  </CommonList>
);

export const GlobalPresentEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="全体付与" />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <SelectInput
        source="ContentType"
        label="コンテンツタイプ"
        choices={ContentTypes}
        required
      />
      <NumberInput source="ContentId" label="コンテンツID" required />
      <NumberInput source="ContentNum" label="個数" required />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" required />
      <DateTimeWithSecInput source="ExpiredAt" label="受取期限" required />
      <TextInput
        source="CustomMessage"
        label="カスタムメッセージ"
        helperText="ユーザーに表示するメッセージ。省略可。"
      />
      <DateTimeWithSecInput
        source="UserCreatedAtBefore"
        label="ユーザー作成日"
        helperText="指定した日付以前に作成されたユーザーに付与。（未指定可）"
      />
      <DateTimeWithSecInput
        source="TutorialCompletedAtBefore"
        label="チュートリアル完了日"
        helperText="指定した日付以前にチュートリアルを完了したユーザーに付与。（未指定可）"
      />
    </SimpleForm>
  </Edit>
);

export const GlobalPresentCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <SelectInput
        source="ContentType"
        label="コンテンツタイプ"
        choices={ContentTypes}
        required
      />
      <NumberInput source="ContentId" label="コンテンツID" required />
      <NumberInput source="ContentNum" label="個数" required />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" required />
      <DateTimeWithSecInput source="ExpiredAt" label="受取期限" required />
      <TextInput
        source="CustomMessage"
        label="カスタムメッセージ"
        helperText="ユーザーに表示するメッセージ。省略可。"
      />
      <DateTimeWithSecInput
        source="UserCreatedAtBefore"
        label="ユーザー作成日"
        helperText="指定した日付以前に作成されたユーザーに付与。（未指定可）"
      />
      <DateTimeWithSecInput
        source="TutorialCompletedAtBefore"
        label="チュートリアル完了日"
        helperText="指定した日付以前にチュートリアルを完了したユーザーに付与。（未指定可）"
      />
    </SimpleForm>
  </Create>
);
