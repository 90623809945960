export class AnnouncementTemplateLoader {
  constructor() {}

  public static async load(url: string): Promise<string> {
    const response = await fetch(url);
    if (!response) {
      return "";
    }

    return await response.text();
  }

  public static replaceURL(html: string, url: string): string {
    return html.replaceAll("${URL}", url);
  }
}
