import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userBirthdayLoginBonusFilters = [
  <NumberInput source="CharacterId" label="CharacterId" />,
];

export const UserBirthdayLoginBonusList = (props: any) => (
  <CommonList {...props} addFilters={userBirthdayLoginBonusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="CharacterId" label="CharacterId" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
