import {
  Datagrid,
  TextField,
  BooleanField,
  NumberInput,
  BooleanInput,
} from "react-admin";
import { CommonList, NumField } from "../Common";

const userPushNotificationFilters = [
  <BooleanInput source="IsEnabled" label="通知受取" />,
  <NumberInput source="SilentMinutesFrom" label="受け取らない時間From" />,
  <NumberInput source="SilentMinutesTo" label="受け取らない時間To" />,
  <BooleanInput
    source="IsSilentMinutesEnabled"
    label="受け取らない時間を有効にするか"
  />,
];

export const UserPushNotificationList = (props: any) => (
  <CommonList {...props} addFilters={userPushNotificationFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <BooleanField source="IsEnabled" label="通知受取" />
      <NumField source="SilentMinutesFrom" label="受け取らない時間From" />
      <NumField source="SilentMinutesTo" label="受け取らない時間To" />
      <BooleanField
        source="IsSilentMinutesEnabled"
        label="受け取らない時間を有効にするか"
      />
    </Datagrid>
  </CommonList>
);
