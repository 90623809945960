import { NumberInput, BooleanInput, BooleanField } from "react-admin";
import {
  CommonLogUserFilter,
  CommonLogUserList,
  NumField,
  ReferenceMissionField,
} from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="MissionId" label="ミッションID" />,
  <NumberInput source="MissionValue" label="値" />,
  <NumberInput source="BeforeProgress" label="進捗Before" />,
  <NumberInput source="AfterProgress" label="進捗After" />,
  <BooleanInput source="IsAchieved" label="達成済み" />,
]);

export const LogUserMissionList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="MissionId" label="ミッションID" />
    <ReferenceMissionField label="ミッション名" />
    <NumField source="MissionValue" label="値" />
    <NumField source="BeforeProgress" label="進捗Before" />
    <NumField source="AfterProgress" label="進捗After" />
    <BooleanField source="IsAchieved" label="達成済み" />
  </CommonLogUserList>
);
