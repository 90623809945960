import { Datagrid, TextField, TextInput } from "react-admin";
import { CommonList, YMDHMDateField } from "../Common";

const userBanHistoryFilters = [
  <TextInput source="OperationType" label="OperationType" />,
];

export const UserBanHistoryList = (props: any) => (
  <CommonList {...props} addFilters={userBanHistoryFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="UserId" />
      <YMDHMDateField source="ExpiredAt" label="ExpiredAt" />
      <TextField source="OperationType" label="OperationType" />
      <YMDHMDateField source="CreatedAt" label="CreatedAt" />
    </Datagrid>
  </CommonList>
);
