import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userBattlePassRewardFilters = [
  <NumberInput source="BattlePassRewardId" label="BattlePassRewardId" />,
];

export const UserBattlePassRewardList = (props: any) => (
  <CommonList {...props} addFilters={userBattlePassRewardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="BattlePassRewardId" label="BattlePassRewardId" />
      <YMDHMSDateField source="ReceivedAt" label="受取日時" />
    </Datagrid>
  </CommonList>
);
