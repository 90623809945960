import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="TowerEventId" label="タワーイベントID" />,
  <NumberInput source="TotalScore" label="合計スコア" />,
]);

export const LogUserTowerScoreRewardReceiveList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="TowerEventId" label="タワーイベントID" />
    <NumField source="TotalScore" label="合計スコア" />
  </CommonLogUserList>
);
