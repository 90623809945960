import {
  useDataProvider,
  SelectInput,
  GetListResult,
  GetListParams,
} from "react-admin";
import { useMemo, useState } from "react";

const defaultListParam: GetListParams = {
  pagination: {
    page: 1,
    perPage: 1000,
  },
  sort: {
    field: "",
    order: "DESC",
  },
  filter: undefined,
};

type Gacha = {
  id: string;
  title: string;
  image: string;
  priority: number;
  point_item_id: number;
  gacha_stamp_id: number;
  open_at: Date;
  close_at: Date;
  content_group_id_last: number;
};

type GachaListDropDownProps = {
  gachaGroupID: number;
  setSelectedGachaID: React.Dispatch<React.SetStateAction<number>>;
};

export const GachaListDropDown = ({
  gachaGroupID,
  setSelectedGachaID,
}: GachaListDropDownProps) => {
  const dataProvider = useDataProvider();
  const [gachaListResult, setGachaListResult] =
    useState<GetListResult<Gacha>>();

  useMemo(() => {
    (async () => {
      if (gachaGroupID == 0) return;

      defaultListParam.filter = { GachaGroupID: gachaGroupID };
      setGachaListResult(
        await dataProvider.getList<Gacha>("Gacha", defaultListParam)
      );
    })();
  }, [gachaGroupID]);

  return (
    <>
      {gachaListResult && (
        <SelectInput
          source="gacha_list"
          choices={gachaListResult.data}
          optionText={(choice) =>
            `${choice.id}: ${choice.title}, 回数: ${
              choice.frequency
            }, 確定枠: ${choice.content_group_id_last > 0 ? "あり" : "なし"}`
          }
          isLoading={gachaListResult ? false : true}
          onChange={(selected) => {
            setSelectedGachaID(selected.target.value);
          }}
        />
      )}
    </>
  );
};
