export function RarityTypeString(rarityType: number) {
  switch (rarityType) {
    case 0:
      return "N";
    case 1:
      return "R";
    case 2:
      return "SR";
    case 3:
      return "SSR";
    case 4:
      return "HR";
    default:
      return "未定義";
  }
}
