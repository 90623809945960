import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="Number" label="番号" />,
  <NumberInput source="BoxGachaGroupId" label="ボックスガチャグループID" />,
  <NumberInput source="BoxGachaPrizeId" label="ボックスガチャ賞品ID" />,
]);

export const LogUserBoxGachaExecPrizeList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="Number" label="番号" />
    <NumField source="BoxGachaGroupId" label="ボックスガチャグループID" />
    <NumField source="BoxGachaPrizeId" label="ボックスガチャ賞品ID" />
  </CommonLogUserList>
);
