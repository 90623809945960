import { Datagrid, NumberInput, TextField, TextInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userActPassPurchaseFilters = [
  <TextInput source="id" label="ID" />,
  <TextInput source="UserId" label="ユーザーID" />,
  <NumberInput source="ActPassId" label="ActPassId" />,
  <NumberInput source="ActPassGroupId" label="ActPassGroupId" />,
];

export const UserActPassPurchaseList = (props: any) => (
  <CommonList {...props} filters={userActPassPurchaseFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" label="ID" />
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="ActPassId" label="ActPassId" />
      <NumField source="ActPassGroupId" label="ActPassGroupId" />
      <YMDHMSDateField source="PurchasedAt" label="購入日時" />
      <YMDHMSDateField source="OpenAt" label="開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="期限" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
