import {
  Datagrid,
  TextField,
  NumberInput,
  BooleanField,
  BooleanInput,
} from "react-admin";
import { CommonList, NumField } from "../Common";

const userTowerStageFilters = [
  <NumberInput source="TowerStageId" label="TowerStageId" />,
  <BooleanInput source="IsPlayed" label="プレイ済み" />,
  <BooleanInput source="IsCleared" label="クリア済み" />,
  <NumberInput source="Score" label="クリアスコア" />,
  <NumberInput source="LimitedFormationId" label="LimitedFormationId" />,
];

export const UserTowerStageList = (props: any) => (
  <CommonList {...props} addFilters={userTowerStageFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="TowerStageId" label="TowerStageId" />
      <BooleanField source="IsPlayed" label="プレイ済み" />
      <BooleanField source="IsCleared" label="クリア済み" />
      <NumField source="Score" label="クリアスコア" />
      <NumField source="LimitedFormationId" label="LimitedFormationId" />
    </Datagrid>
  </CommonList>
);
