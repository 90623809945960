import { Datagrid, TextField, TextInput } from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const filters = [
  <TextInput source="TransactionId" label="トランザクションID" />,
  <TextInput source="ProductId" label="プロダクトID" />,
];

export const UserAppPayBilledHistoryList = (props: any) => (
  <CommonList {...props} addFilters={filters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="TransactionId" label="トランザクションID" />
      <TextField source="ProductId" label="プロダクトID" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
