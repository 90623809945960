import { CommonList, YMDHMSDateField, NumField } from "components/Common";
import { Datagrid, TextField, NumberInput, BooleanField } from "react-admin";

const userTutorialProgressFilters = [
  <NumberInput source="Progress" label="進捗" />,
];

export const UserTutorialProgressList = (props: any) => (
  <CommonList {...props} addFilters={userTutorialProgressFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="Progress" label="進捗" />
      <BooleanField source="IsGachaExecuted" label="ガチャ実行" />
      <YMDHMSDateField source="CompletedAt" label="完了日時" />
      <NumField source="TrainingProgress" label="強化編成チュートリアル進捗" />
      <YMDHMSDateField
        source="TrainingCompletedAt"
        label="強化編成チュートリアル完了日時"
      />
    </Datagrid>
  </CommonList>
);
