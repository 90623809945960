import { NumberInput } from "react-admin";
import {
  CommonLogUserList,
  CommonLogUserFilter,
  NumField,
  ReferenceItemField,
} from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="ItemId" label="アイテムID" />,
  <NumberInput source="Count" label="獲得数" />,
  <NumberInput source="BeforeCount" label="獲得数Before" />,
  <NumberInput source="AfterCount" label="獲得数After" />,
]);

export const LogUserItemList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="ItemId" label="アイテムID" />
    <ReferenceItemField label="アイテム名" />
    <NumField source="Count" label="獲得数" />
    <NumField source="BeforeCount" label="獲得数Before" />
    <NumField source="AfterCount" label="獲得数After" />
  </CommonLogUserList>
);
