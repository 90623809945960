import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="Step" label="ステップ" />,
]);

export const LogUserStoneStaminaRecoveryList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="Step" label="ステップ" />
  </CommonLogUserList>
);
