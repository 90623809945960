import { useState } from "react";

import {
  CreateParams,
  SimpleForm,
  Title,
  useDataProvider,
  Toolbar,
  SaveButton,
  NumberInput,
  DateTimeInput,
  downloadCSV,
} from "react-admin";
import { Card, Button } from "@mui/material";
import { GachaGroupListDropDown } from "./GachaGroupListDropDown";
import { GachaListDropDown } from "./GachaListDropDown";
import { GachaResultListView } from "./GachaResultList";

type Content = {
  id: number;
  gacha_group_id: number;
  card_id: number;
  card_type: number;
  weight: number;
  is_pickup: boolean;
  count: number;
  is_last: boolean;
};

export type SimulatorResult = {
  id: number;
  contents: Content[];
  total_weight: number;
  total_weight_last: number;
  total_count: number;
  total_count_last: number;
};

const toCSV = (simulatorResult: SimulatorResult): string => {
  const header = "出現数,実値,表記,カード種別,カードID,ピックアップ,確定枠";
  const contents: string[] = simulatorResult.contents.map((content) => {
    const totalWeight = content.is_last
      ? simulatorResult.total_weight_last
      : simulatorResult.total_weight;
    const drawCount = content.is_last
      ? simulatorResult.total_count_last
      : simulatorResult.total_count;
    const cardType = content.card_type === 1 ? "MCカード" : "メモリーカード";

    return `${content.count},${(content.count / drawCount) * 100},${
      (content.weight / totalWeight) * 100
    },${cardType},${
      content.card_id
    },${content.is_pickup.toString()},${content.is_last.toString()}`;
  });

  return header + "\n" + contents.join("\n");
};

type ExecuteGachaSimulatorToolbarProps = {
  selectedGachaID: number;
  setSimulatorResult: React.Dispatch<
    React.SetStateAction<SimulatorResult | undefined>
  >;
  count: number;
};

type ExecuteGachaSimulatorRequest = {
  id: number;
  times: number;
};

const ExecuteGachaSimulatorToolbar = ({
  selectedGachaID,
  setSimulatorResult,
  count,
}: ExecuteGachaSimulatorToolbarProps) => {
  const dataProvider = useDataProvider();

  const executeGacha = async () => {
    const res = await dataProvider.create<SimulatorResult>("GachaSimulator", {
      data: {
        id: Number(selectedGachaID),
        times: Number(count),
      } as ExecuteGachaSimulatorRequest,
    } as CreateParams<ExecuteGachaSimulatorRequest>);

    res.data.contents.sort((a, b) => {
      if (a.is_last && !b.is_last) {
        return 1;
      } else if (!a.is_last && b.is_last) {
        return -1;
      } else {
        return 0;
      }
    });

    setSimulatorResult(res.data);
  };

  return (
    <Toolbar>
      <SaveButton label="実行" onClick={executeGacha} disabled={false} />
    </Toolbar>
  );
};

export const GachaSimulator = (props: any) => {
  const [selectedGachaGroupID, setSelectedGachaGroupID] = useState<number>(0);
  const [selectedGachaID, setSelectedGachaID] = useState<number>(0);
  const [simulatorResult, setSimulatorResult] = useState<SimulatorResult>();
  const [count, setCount] = useState<number>(0);
  const [openTime, setOpenTime] = useState<Date>();
  const [isDisableOpenDate, setIsDisableOpenDate] = useState<boolean>(false);
  const [isDisableSelectGachaID, setIsDisableSelectGachaID] =
    useState<boolean>(false);

  // IDを直接指定する使いかたと日付から直接指定する場合は同時に利用出来ないのでもう片方はdisableにする
  // 入力が取り消された場合はどちらも使える状態にリセット
  const setDisable = (
    value: string,
    setDisable: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (value === "") {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  const onUpdateDateTimeInput = (e: any) => {
    setOpenTime(new Date(e.target.value));
    setDisable(e.target.value as string, setIsDisableSelectGachaID);
  };

  const onUpdateSelectGachaID = (e: any) => {
    setSelectedGachaID(e.target.value);
    setDisable(e.target.value as string, setIsDisableOpenDate);
  };

  return (
    <Card {...props}>
      <Title title="GachaSimulator" />
      <SimpleForm
        toolbar={ExecuteGachaSimulatorToolbar({
          selectedGachaID: selectedGachaID,
          setSimulatorResult: setSimulatorResult,
          count: count,
        })}
      >
        <NumberInput
          source="inputGachaId"
          label="ガチャのIDを入力"
          onChange={onUpdateSelectGachaID}
          disabled={isDisableSelectGachaID}
        />
        <DateTimeInput
          source="openDate"
          label="ガチャの開催日を入力"
          onChange={onUpdateDateTimeInput}
          disabled={isDisableOpenDate}
        />
        <GachaGroupListDropDown
          openTime={openTime}
          setSelectedGachaGroupID={setSelectedGachaGroupID}
        />
        <GachaListDropDown
          gachaGroupID={selectedGachaGroupID}
          setSelectedGachaID={setSelectedGachaID}
        />
        <NumberInput
          source="Count"
          onChange={(e) => setCount(e.target.value)}
        />
        {simulatorResult && (
          <Button
            onClick={() => {
              downloadCSV(toCSV(simulatorResult), "download.csv");
            }}
          >
            CSV出力
          </Button>
        )}
      </SimpleForm>
      {simulatorResult && <GachaResultListView contents={simulatorResult} />}
    </Card>
  );
};
