import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userBattlePassPurchaseFilters = [
  <NumberInput source="BattlePassSeasonId" label="BattlePassSeasonId" />,
];

export const UserBattlePassPurchaseList = (props: any) => (
  <CommonList {...props} addFilters={userBattlePassPurchaseFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="BattlePassSeasonId" label="BattlePassSeasonId" />
      <YMDHMSDateField source="PurchasedAt" label="購入日時" />
    </Datagrid>
  </CommonList>
);
