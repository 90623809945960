import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="QuestPlayLimitId" label="クエストプレイ制限ID" />,
  <NumberInput source="PlayCount" label="プレイ回数" />,
  <NumberInput source="RecoveryCount" label="回復回数" />,
]);

export const LogUserQuestPlayLimitList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="QuestPlayLimitId" label="クエストプレイ制限ID" />
    <NumField source="PlayCount" label="プレイ回数" />
    <NumField source="RecoveryCount" label="回復回数" />
  </CommonLogUserList>
);
