import React, { useState } from "react";
import { DateTimeInput, TextInput, useRecordContext } from "react-admin";

export const TextWithCharCountInput = ({
  source,
  maxLength,
  ...props
}: any) => {
  const record = useRecordContext();
  const [charCount, setCharCount] = useState(
    record ? record[source]?.length : 0
  );

  const handleTextInputChange = (event: any) => {
    setCharCount(event.target.value.length);
  };

  return (
    <div>
      <TextInput
        source={source}
        onChange={handleTextInputChange}
        defaultValue={record ? record[source] : ""}
        {...(maxLength && { maxLength })}
        {...props}
      />
      <div>
        <div>{maxLength ? `${charCount} / ${maxLength}` : charCount}</div>
      </div>
    </div>
  );
};

export const DateTimeWithSecInput = (props: any) => (
  <DateTimeInput {...props} inputProps={{ step: 1 }} format={formatDateTime} />
);

const leftPad =
  (nb = 2) =>
  (value: any) =>
    ("0".repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);

const convertDateToString = (value: Date) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return "";
  const yyyy = leftPad4(value.getFullYear());
  const MM = leftPad2(value.getMonth() + 1);
  const dd = leftPad2(value.getDate());
  const hh = leftPad2(value.getHours());
  const mm = leftPad2(value.getMinutes());
  const ss = leftPad2(value.getSeconds());
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}`;
};

const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

const formatDateTime = (value: string | Date) => {
  if (value == null || value === "") {
    return "";
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  }

  if (dateTimeRegex.test(value)) {
    return value;
  }

  return convertDateToString(new Date(value));
};
