import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="Count" label="戦闘順" />,
  <NumberInput source="EnemyGroupId_1" label="敵グループID 1" />,
  <NumberInput source="EnemyGroupId_2" label="敵グループID 2" />,
  <NumberInput source="EnemyGroupId_3" label="敵グループID 3" />,
]);

// TODO JSON field
export const LogUserBattleSkipDetailList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="Count" label="戦闘順" />
    <NumField source="EnemyGroupId_1" label="敵グループID 1" />
    <NumField source="EnemyGroupId_2" label="敵グループID 2" />
    <NumField source="EnemyGroupId_3" label="敵グループID 3" />
  </CommonLogUserList>
);
