import { NumberInput, TextField, TextInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <TextInput source="PresentId" label="プレゼントID" />,
  <NumberInput source="ContentType" label="コンテンツタイプ" />,
  <NumberInput source="ContentId" label="コンテンツID" />,
  <NumberInput source="ContentNum" label="コンテンツ数" />,
]);

export const LogUserPresentAcquisitionList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <TextField source="PresentId" label="プレゼントID" />
    <NumField source="ContentType" label="コンテンツタイプ" />
    <NumField source="ContentId" label="コンテンツID" />
    <NumField source="ContentNum" label="コンテンツ数" />
  </CommonLogUserList>
);
