import {
  Button,
  CreateButton,
  ExportButton,
  FilterButton,
  TopToolbar,
  useRefresh,
  useResourceDefinition,
} from "react-admin";
import RefreshIcon from "@mui/icons-material/Refresh";

export const CommonActions = () => {
  const resource = useResourceDefinition();
  const refresh = useRefresh();

  const handleRefresh = () => {
    refresh();
  };

  return (
    <TopToolbar>
      <FilterButton />
      {resource.hasCreate && <CreateButton />}
      <ExportButton />

      <Button
        label="再表示"
        onClick={handleRefresh}
        startIcon={<RefreshIcon />}
      />
    </TopToolbar>
  );
};
