import { NumberInput } from "react-admin";
import {
  CommonLogUserFilter,
  CommonLogUserList,
  NumField,
  YMDHMSDateField,
  DateTimeWithSecInput,
} from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="SlotNumber" label="スロット番号" />,
  <NumberInput source="CharacterType" label="キャラクタータイプ" />,
  <NumberInput source="ActLevel" label="アクトレベル" />,
  <DateTimeWithSecInput source="StartedAt_from" label="開始時刻From" />,
  <DateTimeWithSecInput source="StartedAt_to" label="開始時刻To" />,
  <DateTimeWithSecInput
    source="LastReceivedAt_from"
    label="最終受取時刻From"
  />,
  <DateTimeWithSecInput source="LastReceivedAt_to" label="最終受取時刻To" />,
]);

export const LogUserActStartTimeList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="SlotNumber" label="スロット番号" />
    <NumField source="CharacterType" label="キャラクタータイプ" />
    <NumField source="ActLevel" label="アクトレベル" />
    <YMDHMSDateField source="StartedAt" label="開始時刻" />
    <YMDHMSDateField source="LastReceivedAt" label="最終受取時刻" />
  </CommonLogUserList>
);
