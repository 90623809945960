import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="TowerStageId" label="タワーステージID" />,
]);

export const LogUserTowerBattleRetireList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="TowerStageId" label="タワーステージID" />
  </CommonLogUserList>
);
