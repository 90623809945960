export type FileInputResult = {
  rawFile: File;
  src: string;
  title: string;
};

export const readFileAsText = (file: File): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        resolve(e.target.result.toString());
      }
      reject(new DOMException("Problem read input file."));
    };
    fileReader.readAsText(file);
  });
};
