import React, { useContext, useEffect, useState } from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import "./App.css";
import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";
import {
  MaintenanceList,
  MaintenanceCreate,
  MaintenanceEdit,
} from "./components/Maintenance";
import {
  PushNotificationList,
  PushNotificationCreate,
  PushNotificationEdit,
} from "./components/PushNotification";
import {
  ServiceMaintenanceList,
  ServiceMaintenanceCreate,
  ServiceMaintenanceEdit,
} from "./components/ServiceMaintenance";
import { UserList, UserShow } from "./components/User";
import { UserBanList, UserBanCreate, UserBanEdit } from "./components/UserBan";
import { UserBanHistoryList } from "./components/UserBanHistory";
import { UserBattlePassPurchaseList } from "./components/UserBattlePassPurchase";
import { UserBattlePassRewardList } from "./components/UserBattlePassReward";
import { UserBattlePassSeasonList } from "./components/UserBattlePassSeason";
import {
  UserCharacterCardList,
  UserCharacterCardShow,
} from "./components/UserCharacterCard";
import {
  UserEquipmentCardList,
  UserEquipmentCardShow,
} from "./components/UserEquipmentCard";
import { UserGachaList } from "./components/UserGacha";
import { UserItemList } from "./components/UserItem";
import {
  UserMaintenanceList,
  UserMaintenanceCreate,
  UserMaintenanceEdit,
} from "./components/UserMaintenance";
import { UserMissionList } from "./components/UserMission";
import { UserMoneyList } from "./components/UserMoney";
import { UserPartyCharacterCardList } from "./components/UserPartyCharacterCard";
import { UserPartyEquipmentCardList } from "./components/UserPartyEquipmentCard";
import { UserPartyList } from "./components/UserParty";
import { UserPresentList } from "./components/UserPresent";
import { UserRankList } from "./components/UserRank";
import {
  AuthUserList,
  AuthUserEdit,
  AuthUserCreate,
} from "./components/AuthUser";
import { UserShopMerchandiseList } from "./components/UserShopMerchandise";
import { UserStaminaList } from "./components/UserStamina";
import { UserStoneList } from "./components/UserStone";
import {
  AnnouncementList,
  AnnouncementCreate,
  AnnouncementEdit,
} from "./components/Announcement";
import {
  AdminPresentCreate,
  AdminPresentEdit,
  AdminPresentList,
} from "./components/Present";
import {
  GlobalPresentList,
  GlobalPresentCreate,
  GlobalPresentEdit,
} from "./components/GlobalPresent";
import { BlockWordList, BlockWordCreate } from "./components/BlockWord";
import { Dashboard } from "./components/Dashboard";
import { GachaSimulator } from "./components/GachaSimulator";
import { UserActCharacterList } from "./components/UserActCharacter";
import { UserActStartTimeList } from "./components/UserActStartTime";
import { Debug } from "./components/Debug";
import { MyLayout } from "./components/Layout";
import {
  ApiEndpointContext,
  ApiEndpointProvider,
} from "./providers/apiEndpointProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { AdminElm, MemberElm, ReaderElm, PlannerElm } from "./utils/role";
import { Group } from "./config/group";
import { Theme } from "./config/theme";
import { UserActPassPurchaseList } from "./components/UserActPassPurchase";
import { UserAppStoreBilledHistoryList } from "./components/UserAppStoreBilledHistory";
import { UserBattleStatusList } from "./components/UserBattleStatus";
import { UserBilledStoneList } from "./components/UserBilledStone";
import { UserBirthdayLoginBonusList } from "./components/UserBirthdayLoginBonus";
import { UserBoxGachaGroupList } from "./components/UserBoxGachaGroup";
import { UserBoxGachaGroupPrizeList } from "./components/UserBoxGachaGroupPrize";
import { UserCardStoryEpisodeList } from "./components/UserCardStoryEpisode";
import { UserCharacterPanelList } from "./components/UserCharacterPanel";
import { UserCharacterTopList } from "./components/UserCharacterTop";
import { UserCommonBilledHistoryList } from "./components/UserCommonBilledHistory";
import { UserDeviceTokenList } from "./components/UserDeviceToken";
import { UserEquipmentCardAcquisitionList } from "./components/UserEquipmentCardAcquisition";
import { UserEventLoginBonusList } from "./components/UserEventLoginBonus";
import { UserGachaStampList } from "./components/UserGachaStamp";
import { UserHonorList } from "./components/UserHonor";
import { UserHonorMissionList } from "./components/UserHonorMission";
import { UserLoginList } from "./components/UserLogin";
import { UserMissionAchievedCountList } from "./components/UserMissionAchievedCount";
import { UserNormalLoginBonusList } from "./components/UserNormalLoginBonus";
import { UserPushNotificationList } from "./components/UserPushNotification";
import { UserQuestBattleDifficultyList } from "./components/UserQuestBattleDifficulty";
import { UserQuestBattleMissionList } from "./components/UserQuestBattleMission";
import { UserQuestPlayLimitList } from "./components/UserQuestPlayLimit";
import { UserQuestStoryEpisodeList } from "./components/UserQuestStoryEpisode";
import { UserReceivedGlobalPresentList } from "./components/UserReceivedGlobalPresent";
import { UserSeasonPassPurchaseList } from "./components/UserSeasonPassPurchase";
import { UserStartedMissionGroupList } from "./components/UserStartedMissionGroup";
import { UserTowerList } from "./components/UserTower";
import { UserTowerBattleStatusList } from "./components/UserTowerBattleStatus";
import { UserTowerStageList } from "./components/UserTowerStage";
import { UserTutorialProgressList } from "./components/UserTutorialProgress";
import { LogUserPresentList } from "./components/LogUserPresent";
import { UserHomeCardList } from "./components/UserHomeCard";
import { UserLastPartyNumberList } from "./components/UserLastPartyNumber";
import { UserAccountMigrationTokenList } from "./components/UserAccountMigrationToken";
import { UserStepGachaGroupList } from "./components/UserStepGachaGroup";
import { UserLimitedGachaList } from "./components/UserLimitedGacha";
import { UserDeviceHistoryList } from "./components/UserDeviceHistory";
import { LogUserGachaContentList } from "./components/LogUserGachaContent";
import { UserPlayStoreBilledHistoryList } from "./components/UserPlayStoreBilledHistory";
import { LogAdminAccessList } from "./components/LogAdminAccess";
import { UserExternalBilledStoneList } from "components/UserExternalBilledStone";
import { LogUserActionList, LogUserActionShow } from "components/LogUserAction";
import { LogUserItemList } from "components/LogUserItem";
import { LogUserMoneyList } from "components/LogUserMoney";
import { LogUserStoneList } from "components/LogUserStone";
import { LogUserStaminaList } from "components/LogUserStamina";
import { LogUserCharacterCardList } from "components/LogUserCharacterCard";
import { LogUserEquipmentCardList } from "components/LogUserEquipmentCard";
import { LogUserCharacterCardExpList } from "components/LogUserCharacterCardExp";
import { LogUserCharacterPanelList } from "components/LogUserCharacterPanel";
import { LogUserPresentAcquisitionList } from "components/LogUserPresentAcquisition";
import { LogUserGachaList } from "components/LogUserGacha";
import { LogUserGachaDetailList } from "components/LogUserGachaDetail";
import { LogUserGachaStampList } from "components/LogUserGachaStamp";
import { LogUserStepGachaGroupList } from "components/LogUserStepGachaGroup";
import { LogUserShopMerchandiseList } from "components/LogUserShopMerchandise";
import { LogUserAccountMigrationRecoveryList } from "components/LogUserAccountMigrationRecovery";
import { LogUserBattleStartList } from "components/LogUserBattleStart";
import { LogUserQuestPlayLimitList } from "components/LogUserQuestPlayLimit";
import { LogUserBattleSkipList } from "components/LogUserBattleSkip";
import { LogUserBattleSkipDetailList } from "components/LogUserBattleSkipDetail";
import { LogUserBattleContinueList } from "components/LogUserBattleContinue";
import { LogUserBattleRetireList } from "components/LogUserBattleRetire";
import { LogUserBattleFinishList } from "components/LogUserBattleFinish";
import { LogUserEquipmentCardExpList } from "components/LogUserEquipmentCardExp";
import { LogUserEquipmentCardRankList } from "components/LogUserEquipmentCardRank";
import { LogUserBoxGachaExecList } from "components/LogUserBoxGachaExec";
import { LogUserBoxGachaExecPrizeList } from "components/LogUserBoxGachaExecPrize";
import { LogUserActStartTimeList } from "components/LogUserActStartTime";
import { LogUserActReceiveItemList } from "components/LogUserActReceiveItem";
import { LogUserActPresentItemList } from "components/LogUserActPresentItem";
import { LogUserNormalLoginBonusList } from "components/LogUserNormalLoginBonus";
import { LogUserEventLoginBonusList } from "components/LogUserEventLoginBonus";
import { LogUserSeasonPassLoginBonusList } from "components/LogUserSeasonPassLoginBonus";
import { LogUserActPassLoginBonusList } from "components/LogUserActPassLoginBonus";
import { LogUserBirthdayLoginBonusList } from "components/LogUserBirthdayLoginBonus";
import { LogUserRankExpList } from "components/LogUserRankExp";
import { LogUserMissionList } from "components/LogUserMission";
import { LogUserMissionAchievedCountList } from "components/LogUserMissionAchievedCount";
import { LogUserMissionRewardList } from "components/LogUserMissionReward";
import { LogUserMissionAchievedCountRewardList } from "components/LogUserMissionAchievedCountReward";
import { LogUserBattlePassExpList } from "components/LogUserBattlePassExp";
import { LogUserPurchaseList } from "components/LogUserPurchase";
import { LogUserProfileList } from "components/LogUserProfile";
import { LogUserTowerBattleStartList } from "components/LogUserTowerBattleStart";
import { LogUserTowerBattleContinueList } from "components/LogUserTowerBattleContinue";
import { LogUserTowerBattleRetireList } from "components/LogUserTowerBattleRetire";
import { LogUserBoxGachaResetList } from "components/LogUserBoxGachaReset";
import { LogUserTowerBattleFinishList } from "components/LogUserTowerBattleFinish";
import { LogUserTowerList } from "components/LogUserTower";
import { LogUserTowerStageList } from "components/LogUserTowerStage";
import { LogUserTowerStageLimitedFormationList } from "components/LogUserTowerStageLimitedFormation";
import { LogUserTowerScoreRewardReceiveList } from "components/LogUserTowerScoreRewardReceive";
import { LogUserHonorMissionList } from "components/LogUserHonorMission";
import { UserStoneDetailList } from "components/UserStoneDetail";
import { UserAppPayBilledHistoryList } from "components/UserAppPayBilledHistory";
import { UserStoneStaminaRecoveryList } from "./components/UserStoneStaminaRecovery";
import { LogUserStoneStaminaRecoveryList } from "components/LogUserStoneStaminaRecovery";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
});

const App = () => {
  return (
    <ApiEndpointProvider>
      <QueryClientProvider client={queryClient}>
        <AppInner />
      </QueryClientProvider>
    </ApiEndpointProvider>
  );
};

const AppInner = () => {
  const { apiEndpoint } = useContext(ApiEndpointContext);
  const [appDataProvider, setDataProvider] = useState(() =>
    dataProvider(apiEndpoint)
  );

  useEffect(() => {
    setDataProvider(() => dataProvider(apiEndpoint));
  }, [apiEndpoint]);

  return (
    <Admin
      layout={MyLayout}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={appDataProvider}
      theme={Theme}
      queryClient={queryClient}
    >
      {(p) => (
        <>
          {/* お知らせ */}
          <Resource
            name="Announcement"
            list={PlannerElm(p, AnnouncementList)}
            create={PlannerElm(p, AnnouncementCreate)}
            edit={PlannerElm(p, AnnouncementEdit)}
            options={{ label: "お知らせ", group: Group.Announcement }}
          />
          <Resource
            name="PushNotification"
            list={PlannerElm(p, PushNotificationList)}
            create={PlannerElm(p, PushNotificationCreate)}
            edit={PlannerElm(p, PushNotificationEdit)}
            options={{ label: "プッシュ通知", group: Group.Announcement }}
          />
          {/* メンテナンス */}
          <Resource
            name="Maintenance"
            list={MemberElm(p, MaintenanceList)}
            create={MemberElm(p, MaintenanceCreate)}
            edit={MemberElm(p, MaintenanceEdit)}
            options={{ label: "メンテナンス", group: Group.Maintenance }}
          />
          <Resource
            name="ServiceMaintenance"
            list={MemberElm(p, ServiceMaintenanceList)}
            create={MemberElm(p, ServiceMaintenanceCreate)}
            edit={MemberElm(p, ServiceMaintenanceEdit)}
            options={{ label: "機能メンテナンス", group: Group.Maintenance }}
          />
          <Resource
            name="UserMaintenance"
            list={MemberElm(p, UserMaintenanceList)}
            create={MemberElm(p, UserMaintenanceCreate)}
            edit={MemberElm(p, UserMaintenanceEdit)}
            options={{
              label: "ユーザーメンテナンス",
              group: Group.Maintenance,
            }}
          />
          {/* ユーザー */}
          <Resource
            name="User"
            list={ReaderElm(p, UserList)}
            show={UserShow}
            options={{ label: "ユーザー", group: Group.User }}
          />
          <Resource
            name="UserCharacterCard"
            list={ReaderElm(p, UserCharacterCardList)}
            show={ReaderElm(p, UserCharacterCardShow)}
            options={{ label: "MCカード", group: Group.User }}
          />
          <Resource
            name="UserCharacterPanel"
            list={ReaderElm(p, UserCharacterPanelList)}
            options={{ label: "キャラクターパネル", group: Group.User }}
          />
          <Resource
            name="UserEquipmentCard"
            list={ReaderElm(p, UserEquipmentCardList)}
            show={ReaderElm(p, UserEquipmentCardShow)}
            options={{ label: "メモリーカード", group: Group.User }}
          />
          <Resource
            name="UserEquipmentCardAcquisition"
            list={ReaderElm(p, UserEquipmentCardAcquisitionList)}
            options={{ label: "獲得メモリーカード", group: Group.User }}
          />
          <Resource
            name="UserItem"
            list={ReaderElm(p, UserItemList)}
            options={{ label: "アイテム", group: Group.User }}
          />
          <Resource
            name="UserPresent"
            list={ReaderElm(p, UserPresentList)}
            options={{ label: "プレゼント", group: Group.User }}
          />
          <Resource
            name="LogUserPresent"
            list={ReaderElm(p, LogUserPresentList)}
            options={{ label: "プレゼントログ", group: Group.User }}
          />
          <Resource
            name="UserReceivedGlobalPresent"
            list={ReaderElm(p, UserReceivedGlobalPresentList)}
            options={{ label: "全体プレゼント受取", group: Group.User }}
          />
          <Resource
            name="UserMission"
            list={ReaderElm(p, UserMissionList)}
            options={{ label: "ミッション", group: Group.User }}
          />
          <Resource
            name="UserMissionAchievedCount"
            list={ReaderElm(p, UserMissionAchievedCountList)}
            options={{ label: "達成数ミッション", group: Group.User }}
          />
          <Resource
            name="UserStartedMissionGroup"
            list={ReaderElm(p, UserStartedMissionGroupList)}
            options={{ label: "開始ミッショングループ", group: Group.User }}
          />
          <Resource
            name="UserShopMerchandise"
            list={ReaderElm(p, UserShopMerchandiseList)}
            options={{ label: "ショップ", group: Group.User }}
          />

          <Resource
            name="UserGacha"
            list={ReaderElm(p, UserGachaList)}
            options={{ label: "ガチャ", group: Group.User }}
          />
          <Resource
            name="LogUserGachaContent"
            list={ReaderElm(p, LogUserGachaContentList)}
            options={{ label: "ガチャ履歴", group: Group.User }}
          />
          <Resource
            name="UserGachaStamp"
            list={ReaderElm(p, UserGachaStampList)}
            options={{ label: "ガチャスタンプ", group: Group.User }}
          />
          <Resource
            name="UserStepGachaGroup"
            list={ReaderElm(p, UserStepGachaGroupList)}
            options={{ label: "ステップアップガチャ", group: Group.User }}
          />
          <Resource
            name="UserLimitedGacha"
            list={ReaderElm(p, UserLimitedGachaList)}
            options={{ label: "限定ガチャ", group: Group.User }}
          />

          <Resource
            name="UserActCharacter"
            list={ReaderElm(p, UserActCharacterList)}
            options={{ label: "ACTキャラ", group: Group.User }}
          />
          <Resource
            name="UserActStartTime"
            list={ReaderElm(p, UserActStartTimeList)}
            options={{ label: "ACT開始時間", group: Group.User }}
          />
          <Resource
            name="UserActPassPurchase"
            list={ReaderElm(p, UserActPassPurchaseList)}
            options={{ label: "ACTパス購入", group: Group.User }}
          />

          <Resource
            name="UserBattlePassPurchase"
            list={ReaderElm(p, UserBattlePassPurchaseList)}
            options={{ label: "バトルパス", group: Group.User }}
          />
          <Resource
            name="UserBattlePassReward"
            list={ReaderElm(p, UserBattlePassRewardList)}
            options={{ label: "バトルパス報酬", group: Group.User }}
          />
          <Resource
            name="UserBattlePassSeason"
            list={ReaderElm(p, UserBattlePassSeasonList)}
            options={{ label: "バトルパスシーズン", group: Group.User }}
          />

          <Resource
            name="UserSeasonPassPurchase"
            list={ReaderElm(p, UserSeasonPassPurchaseList)}
            options={{ label: "シーズンパス", group: Group.User }}
          />

          <Resource
            name="UserAppStoreBilledHistory"
            list={ReaderElm(p, UserAppStoreBilledHistoryList)}
            options={{ label: "AppStore決済履歴", group: Group.User }}
          />
          <Resource
            name="UserPlayStoreBilledHistory"
            list={ReaderElm(p, UserPlayStoreBilledHistoryList)}
            options={{ label: "PlayStore決済履歴", group: Group.User }}
          />
          <Resource
            name="UserAppPayBilledHistory"
            list={ReaderElm(p, UserAppPayBilledHistoryList)}
            options={{ label: "AppPay決済履歴", group: Group.User }}
          />
          <Resource
            name="UserCommonBilledHistory"
            list={ReaderElm(p, UserCommonBilledHistoryList)}
            options={{ label: "決済履歴", group: Group.User }}
          />

          <Resource
            name="UserNormalLoginBonus"
            list={ReaderElm(p, UserNormalLoginBonusList)}
            options={{ label: "通常ログボ", group: Group.User }}
          />
          <Resource
            name="UserEventLoginBonus"
            list={ReaderElm(p, UserEventLoginBonusList)}
            options={{ label: "イベントログボ", group: Group.User }}
          />
          <Resource
            name="UserBirthdayLoginBonus"
            list={ReaderElm(p, UserBirthdayLoginBonusList)}
            options={{ label: "バースデーログボ", group: Group.User }}
          />

          <Resource
            name="UserQuestStoryEpisode"
            list={ReaderElm(p, UserQuestStoryEpisodeList)}
            options={{ label: "ストーリー", group: Group.User }}
          />
          <Resource
            name="UserCardStoryEpisode"
            list={ReaderElm(p, UserCardStoryEpisodeList)}
            options={{ label: "カードストーリー", group: Group.User }}
          />

          <Resource
            name="UserCharacterTop"
            list={ReaderElm(p, UserCharacterTopList)}
            options={{ label: "キャラクターTOP", group: Group.User }}
          />
          <Resource
            name="UserHomeCard"
            list={ReaderElm(p, UserHomeCardList)}
            options={{ label: "ホームカード", group: Group.User }}
          />

          <Resource
            name="UserHonor"
            list={ReaderElm(p, UserHonorList)}
            options={{ label: "称号", group: Group.User }}
          />
          <Resource
            name="UserHonorMission"
            list={ReaderElm(p, UserHonorMissionList)}
            options={{ label: "称号ミッション", group: Group.User }}
          />

          <Resource
            name="UserParty"
            list={ReaderElm(p, UserPartyList)}
            options={{ label: "編成", group: Group.User }}
          />
          <Resource
            name="UserPartyCharacterCard"
            list={ReaderElm(p, UserPartyCharacterCardList)}
            options={{ label: "編成MCカード", group: Group.User }}
          />
          <Resource
            name="UserPartyEquipmentCard"
            list={ReaderElm(p, UserPartyEquipmentCardList)}
            options={{ label: "編成メモリーカード", group: Group.User }}
          />
          <Resource
            name="UserLastPartyNumber"
            list={ReaderElm(p, UserLastPartyNumberList)}
            options={{ label: "最終使用パーティー", group: Group.User }}
          />

          <Resource
            name="UserBattleStatus"
            list={ReaderElm(p, UserBattleStatusList)}
            options={{ label: "バトルステータス", group: Group.User }}
          />
          <Resource
            name="UserQuestBattleDifficulty"
            list={ReaderElm(p, UserQuestBattleDifficultyList)}
            options={{ label: "クエスト", group: Group.User }}
          />
          <Resource
            name="UserQuestBattleMission"
            list={ReaderElm(p, UserQuestBattleMissionList)}
            options={{ label: "クエストミッション", group: Group.User }}
          />
          <Resource
            name="UserQuestPlayLimit"
            list={ReaderElm(p, UserQuestPlayLimitList)}
            options={{ label: "クエスト制限", group: Group.User }}
          />

          <Resource
            name="UserBoxGachaGroup"
            list={ReaderElm(p, UserBoxGachaGroupList)}
            options={{ label: "BOXガチャステップ", group: Group.User }}
          />
          <Resource
            name="UserBoxGachaGroupPrize"
            list={ReaderElm(p, UserBoxGachaGroupPrizeList)}
            options={{ label: "BOXガチャ獲得", group: Group.User }}
          />

          <Resource
            name="UserTower"
            list={ReaderElm(p, UserTowerList)}
            options={{ label: "タワー", group: Group.User }}
          />
          <Resource
            name="UserTowerBattleStatus"
            list={ReaderElm(p, UserTowerBattleStatusList)}
            options={{ label: "タワーバトルステータス", group: Group.User }}
          />
          <Resource
            name="UserTowerStage"
            list={ReaderElm(p, UserTowerStageList)}
            options={{ label: "タワーステージ", group: Group.User }}
          />

          <Resource
            name="UserTutorialProgress"
            list={ReaderElm(p, UserTutorialProgressList)}
            options={{ label: "チュートリアル", group: Group.User }}
          />
          <Resource
            name="UserPushNotification"
            list={ReaderElm(p, UserPushNotificationList)}
            options={{ label: "Push通知設定", group: Group.User }}
          />
          <Resource
            name="UserDeviceToken"
            list={ReaderElm(p, UserDeviceTokenList)}
            options={{ label: "端末トークン", group: Group.User }}
          />
          <Resource
            name="UserDeviceHistory"
            list={ReaderElm(p, UserDeviceHistoryList)}
            options={{ label: "端末履歴", group: Group.User }}
          />
          <Resource
            name="UserLogin"
            list={ReaderElm(p, UserLoginList)}
            options={{ label: "ログイン", group: Group.User }}
          />
          <Resource
            name="UserAccountMigrationToken"
            list={ReaderElm(p, UserAccountMigrationTokenList)}
            options={{ label: "アカウント移行トークン", group: Group.User }}
          />

          {/* ログ */}
          <Resource
            name="LogUserAction"
            list={ReaderElm(p, LogUserActionList)}
            show={ReaderElm(p, LogUserActionShow)}
            options={{ label: "アクションログ", group: Group.Log }}
          />
          <Resource
            name="LogUserItem"
            list={ReaderElm(p, LogUserItemList)}
            options={{ label: "アイテム増減ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserMoney"
            list={ReaderElm(p, LogUserMoneyList)}
            options={{ label: "マネー増減ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserStone"
            list={ReaderElm(p, LogUserStoneList)}
            options={{ label: "ジュエル増減ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserStamina"
            list={ReaderElm(p, LogUserStaminaList)}
            options={{ label: "AP増減ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserCharacterCard"
            list={ReaderElm(p, LogUserCharacterCardList)}
            options={{ label: "MCカード増減ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserEquipmentCard"
            list={ReaderElm(p, LogUserEquipmentCardList)}
            options={{ label: "メモリーカード増減ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserCharacterCardExp"
            list={ReaderElm(p, LogUserCharacterCardExpList)}
            options={{ label: "MCカード経験値増減ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserCharacterPanel"
            list={ReaderElm(p, LogUserCharacterPanelList)}
            options={{ label: "キャラクターパネルログ", group: Group.Log }}
          />
          <Resource
            name="LogUserPresentAcquisition"
            list={ReaderElm(p, LogUserPresentAcquisitionList)}
            options={{ label: "プレゼント獲得ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserGacha"
            list={ReaderElm(p, LogUserGachaList)}
            options={{ label: "ガチャログ", group: Group.Log }}
          />
          <Resource
            name="LogUserGachaDetail"
            list={ReaderElm(p, LogUserGachaDetailList)}
            options={{ label: "ガチャ詳細ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserGachaStamp"
            list={ReaderElm(p, LogUserGachaStampList)}
            options={{ label: "ガチャスタンプログ", group: Group.Log }}
          />
          <Resource
            name="LogUserStepGachaGroup"
            list={ReaderElm(p, LogUserStepGachaGroupList)}
            options={{ label: "ステップガチャログ", group: Group.Log }}
          />
          <Resource
            name="LogUserShopMerchandise"
            list={ReaderElm(p, LogUserShopMerchandiseList)}
            options={{ label: "ショップログ", group: Group.Log }}
          />
          <Resource
            name="LogUserAccountMigrationRecovery"
            list={ReaderElm(p, LogUserAccountMigrationRecoveryList)}
            options={{ label: "アカウント移行復旧ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserBattleStart"
            list={ReaderElm(p, LogUserBattleStartList)}
            options={{ label: "バトル開始ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserQuestPlayLimit"
            list={ReaderElm(p, LogUserQuestPlayLimitList)}
            options={{ label: "クエストプレイ制限ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserBattleSkip"
            list={ReaderElm(p, LogUserBattleSkipList)}
            options={{ label: "バトルスキップログ", group: Group.Log }}
          />
          <Resource
            name="LogUserBattleSkipDetail"
            list={ReaderElm(p, LogUserBattleSkipDetailList)}
            options={{ label: "バトルスキップ詳細ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserBattleContinue"
            list={ReaderElm(p, LogUserBattleContinueList)}
            options={{ label: "バトルコンティニューログ", group: Group.Log }}
          />
          <Resource
            name="LogUserBattleRetire"
            list={ReaderElm(p, LogUserBattleRetireList)}
            options={{ label: "バトルリタイアログ", group: Group.Log }}
          />
          <Resource
            name="LogUserBattleFinish"
            list={ReaderElm(p, LogUserBattleFinishList)}
            options={{ label: "バトル終了ログ", group: Group.Log }}
          />
          <Resource
            name="LogUserEquipmentCardExp"
            list={ReaderElm(p, LogUserEquipmentCardExpList)}
            options={{
              label: "メモリーカード経験値増減ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserEquipmentCardRank"
            list={ReaderElm(p, LogUserEquipmentCardRankList)}
            options={{
              label: "メモリーカードランクログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserBoxGachaExec"
            list={ReaderElm(p, LogUserBoxGachaExecList)}
            options={{
              label: "BOXガチャ実行ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserBoxGachaExecPrize"
            list={ReaderElm(p, LogUserBoxGachaExecPrizeList)}
            options={{
              label: "BOXガチャ実行獲得景品ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserActStartTime"
            list={ReaderElm(p, LogUserActStartTimeList)}
            options={{
              label: "ACT開始時間ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserActReceiveItem"
            list={ReaderElm(p, LogUserActReceiveItemList)}
            options={{
              label: "ACT受取アイテムログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserActPresentItem"
            list={ReaderElm(p, LogUserActPresentItemList)}
            options={{
              label: "ACTプレゼントアイテムログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserNormalLoginBonus"
            list={ReaderElm(p, LogUserNormalLoginBonusList)}
            options={{
              label: "通常ログボログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserEventLoginBonus"
            list={ReaderElm(p, LogUserEventLoginBonusList)}
            options={{
              label: "イベントログボログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserSeasonPassLoginBonus"
            list={ReaderElm(p, LogUserSeasonPassLoginBonusList)}
            options={{
              label: "シーズンパスログボログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserActPassLoginBonus"
            list={ReaderElm(p, LogUserActPassLoginBonusList)}
            options={{
              label: "ACTパスログボログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserBirthdayLoginBonus"
            list={ReaderElm(p, LogUserBirthdayLoginBonusList)}
            options={{
              label: "バースデーログボログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserRankExp"
            list={ReaderElm(p, LogUserRankExpList)}
            options={{
              label: "ランク経験値ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserMission"
            list={ReaderElm(p, LogUserMissionList)}
            options={{
              label: "ミッションログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserMissionAchievedCount"
            list={ReaderElm(p, LogUserMissionAchievedCountList)}
            options={{
              label: "達成数ミッションログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserMissionReward"
            list={ReaderElm(p, LogUserMissionRewardList)}
            options={{
              label: "ミッション報酬受取ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserMissionAchievedCountReward"
            list={ReaderElm(p, LogUserMissionAchievedCountRewardList)}
            options={{
              label: "達成数ミッション報酬受取ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserBattlePassExp"
            list={ReaderElm(p, LogUserBattlePassExpList)}
            options={{
              label: "バトルパス経験値ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserPurchase"
            list={ReaderElm(p, LogUserPurchaseList)}
            options={{
              label: "決済ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserProfile"
            list={ReaderElm(p, LogUserProfileList)}
            options={{
              label: "プロフィールログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserTowerBattleStart"
            list={ReaderElm(p, LogUserTowerBattleStartList)}
            options={{
              label: "タワーバトル開始ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserTowerBattleContinue"
            list={ReaderElm(p, LogUserTowerBattleContinueList)}
            options={{
              label: "タワーバトルコンティニューログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserTowerBattleRetire"
            list={ReaderElm(p, LogUserTowerBattleRetireList)}
            options={{
              label: "タワーバトルリタイアログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserBoxGachaReset"
            list={ReaderElm(p, LogUserBoxGachaResetList)}
            options={{
              label: "BOXガチャリセットログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserTowerBattleFinish"
            list={ReaderElm(p, LogUserTowerBattleFinishList)}
            options={{
              label: "タワーバトル終了ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserTower"
            list={ReaderElm(p, LogUserTowerList)}
            options={{
              label: "タワーログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserTowerStage"
            list={ReaderElm(p, LogUserTowerStageList)}
            options={{
              label: "タワーステージログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserTowerStageLimitedFormation"
            list={ReaderElm(p, LogUserTowerStageLimitedFormationList)}
            options={{
              label: "タワー制限編成更新ログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserTowerScoreRewardReceive"
            list={ReaderElm(p, LogUserTowerScoreRewardReceiveList)}
            options={{
              label: "タワースコア報酬受取りログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserHonorMission"
            list={ReaderElm(p, LogUserHonorMissionList)}
            options={{
              label: "称号ミッションログ",
              group: Group.Log,
            }}
          />
          <Resource
            name="LogUserStoneStaminaRecovery"
            list={ReaderElm(p, LogUserStoneStaminaRecoveryList)}
            options={{
              label: "AP回復ステップログ",
              group: Group.Log,
            }}
          />

          {/* KPI */}
          <Resource
            name="UserRank"
            list={PlannerElm(p, UserRankList)}
            options={{ label: "ランク", group: Group.Kpi }}
          />
          <Resource
            name="UserMoney"
            list={PlannerElm(p, UserMoneyList)}
            options={{ label: "マネー", group: Group.Kpi }}
          />
          <Resource
            name="UserStamina"
            list={PlannerElm(p, UserStaminaList)}
            options={{ label: "AP", group: Group.Kpi }}
          />
          <Resource
            name="UserStoneStaminaRecovery"
            list={PlannerElm(p, UserStoneStaminaRecoveryList)}
            options={{ label: "AP回復ステップ", group: Group.Kpi }}
          />
          <Resource
            name="UserStone"
            list={PlannerElm(p, UserStoneList)}
            options={{ label: "無償ジュエル", group: Group.Kpi }}
          />
          <Resource
            name="UserBilledStone"
            list={PlannerElm(p, UserBilledStoneList)}
            options={{ label: "有償ジュエル", group: Group.Kpi }}
          />
          <Resource
            name="UserExternalBilledStone"
            list={PlannerElm(p, UserExternalBilledStoneList)}
            options={{ label: "外部決済有償ジュエル", group: Group.Kpi }}
          />
          <Resource
            name="UserStoneDetail"
            list={PlannerElm(p, UserStoneDetailList)}
            options={{ label: "ジュエル詳細", group: Group.Kpi }}
          />

          {/* 付与 */}
          <Resource
            name="AdminPresent"
            list={MemberElm(p, AdminPresentList)}
            edit={MemberElm(p, AdminPresentEdit)}
            create={MemberElm(p, AdminPresentCreate)}
            options={{ label: "個別付与", group: Group.Present }}
          />
          <Resource
            name="GlobalPresent"
            list={MemberElm(p, GlobalPresentList)}
            edit={MemberElm(p, GlobalPresentEdit)}
            create={MemberElm(p, GlobalPresentCreate)}
            options={{ label: "全体付与", group: Group.Present }}
          />
          {/* BAN関連 */}
          <Resource
            name="UserBan"
            list={MemberElm(p, UserBanList)}
            create={MemberElm(p, UserBanCreate)}
            edit={MemberElm(p, UserBanEdit)}
            options={{ label: "BANユーザー一覧", group: Group.Ban }}
          />
          <Resource
            name="UserBanHistory"
            list={MemberElm(p, UserBanHistoryList)}
            options={{ label: "BAN履歴", group: Group.Ban }}
          />
          {/* その他 */}
          <Resource
            name="BlockWord"
            list={PlannerElm(p, BlockWordList)}
            create={PlannerElm(p, BlockWordCreate)}
            options={{ group: Group.Other }}
          />
          <CustomRoutes>
            <Route
              path="/gacha_simulator"
              element={PlannerElm(p, <GachaSimulator />)}
            />
            <Route
              path="/debug"
              element={
                process.env.REACT_APP_ENV !== "prod" && PlannerElm(p, <Debug />)
              }
            />
          </CustomRoutes>
          {/* 管理 */}
          <Resource
            name="AuthUser"
            list={AdminElm(p, AuthUserList)}
            edit={AdminElm(p, AuthUserEdit)}
            create={AdminElm(p, AuthUserCreate)}
            options={{ label: "管理画面アカウント", group: Group.Manage }}
          />
          <Resource
            name="LogAdminAccess"
            list={AdminElm(p, LogAdminAccessList)}
            options={{ label: "管理画面操作履歴", group: Group.Manage }}
          />
        </>
      )}
    </Admin>
  );
};

export default App;
