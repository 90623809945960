import { Datagrid, TextField, TextInput, NumberInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const logUserPresentFilters = [
  <TextInput source="PresentId" label="プレゼントID" />,
  <NumberInput source="ContentType" label="ContentType" />,
  <NumberInput source="ContentId" label="ContentId" />,
  <NumberInput source="ContentNum" label="ContentNum" />,
  <TextInput source="AcquisitionFrom" label="付与元" />,
];

export const LogUserPresentList = (props: any) => (
  <CommonList {...props} addFilters={logUserPresentFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="PresentId" label="プレゼントID" />
      <NumField source="ContentType" label="ContentType" />
      <NumField source="ContentId" label="ContentId" />
      <NumField source="ContentNum" label="ContentNum" />
      <TextField source="CustomMessage" label="カスタムメッセージ" />
      <TextField source="AcquisitionFrom" label="付与元" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
