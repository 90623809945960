import { useWatch } from "react-hook-form";
import {
  Create,
  Datagrid,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FunctionField,
  RadioButtonGroupInput,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import { CommonList, EditTitle, YMDHMDateField } from "../Common";

export const UserBanList = (props: any) => (
  <CommonList {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="UserId" />
      <FunctionField
        label="BanType"
        render={(record: any) => calcBanType(record.ExpiredAt).label}
      />
      <YMDHMDateField source="ExpiredAt" label="ExpiredAt" />
      <EditButton />
    </Datagrid>
  </CommonList>
);

export const UserBanCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm toolbar={<UserBanCreateToolbar />}>
      <TextInput source="UserId" validate={required()} />
      <RadioButtonGroupInput
        source="BanType"
        choices={hashToChoices(banTypes)}
        validate={required()}
      />
      <BanExpiredAtInput />
    </SimpleForm>
  </Create>
);

const UserBanCreateToolbar = () => (
  <Toolbar>
    <SaveButton type="button" transform={transformUserBan} alwaysEnable />
  </Toolbar>
);

export const UserBanEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="UserBan" />}>
    <SimpleForm toolbar={<UserBanEditToolbar />}>
      <TextInput disabled source="UserId" />
      <FunctionField
        render={(record: any) => (
          <RadioButtonGroupInput
            source="BanType"
            choices={hashToChoices(banTypes)}
            defaultValue={calcBanType(record.ExpiredAt).value}
          />
        )}
      />
      <BanExpiredAtInput />
    </SimpleForm>
  </Edit>
);

export const BanExpiredAtInput = (props: any) => {
  const banType = useWatch({ name: "BanType" });
  if (banType === banTypes.limited.value) {
    return (
      <DateTimeInput
        source="ExpiredAt"
        label="ExpiredAt"
        validate={validateExpiredAt}
      />
    );
  }
  return <></>;
};

export const UserBanEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton type="button" transform={transformUserBan} />
    <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
  </Toolbar>
);

export function calcBanState(userBan: any) {
  var banState = "非Ban";
  if (!userBan) {
    return banState;
  }
  if (new Date() <= new Date(userBan.ExpiredAt)) {
    banState = "Ban中";
  }
  const banType = calcBanType(userBan.ExpiredAt);
  switch (banType) {
    case banTypes.limited:
      banState += "（" + banType.label + " " + userBan.ExpiredAt + " まで）";
      break;
    case banTypes.permanent:
      banState += "（" + banType.label + "）";
      break;
  }
  return banState;
}

const banTypes = {
  limited: { value: "limited", label: "期間限定" },
  permanent: { value: "permanent", label: "永久", data: new Date(2999, 0, 1) },
};

function calcBanType(expiredAtStr: string) {
  const expiredAt = new Date(expiredAtStr);
  if (expiredAt >= banTypes.permanent.data) {
    return banTypes.permanent;
  } else {
    return banTypes.limited;
  }
}

const hashToChoices = (hash: any) =>
  Object.keys(hash).map((key: any) => ({
    id: hash[key].value,
    name: hash[key].label,
  }));

const validateExpiredAt = (value: any, allValues: any) => {
  if (allValues.BanType === banTypes.limited.value) {
    if (!value) {
      return "Required";
    }
    if (value <= new Date()) {
      return "Needed over current time";
    }
  }
  return undefined;
};

const transformUserBan = (record: any) => {
  if (record.BanType === banTypes.permanent.value) {
    record.ExpiredAt = banTypes.permanent.data;
  }
  return record;
};
