import { NumberInput, BooleanInput, BooleanField } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="TowerStageId" label="タワーステージID" />,
  <NumberInput source="BeforeLimitedFormationId" label="制限編成IDBefore" />,
  <NumberInput source="AfterLimitedFormationId" label="制限編成IDAfter" />,
  <BooleanInput source="IsFree" label="無料で変更" />,
]);

export const LogUserTowerStageLimitedFormationList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="TowerStageId" label="タワーステージID" />
    <NumField source="BeforeLimitedFormationId" label="制限編成IDBefore" />
    <NumField source="AfterLimitedFormationId" label="制限編成IDAfter" />
    <BooleanField source="IsFree" label="無料で変更" />
  </CommonLogUserList>
);
