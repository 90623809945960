import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="SlotNumber" label="スロット番号" />,
  <NumberInput source="CharacterType" label="キャラクタータイプ" />,
  <NumberInput source="ActItemId" label="アクトアイテムID" />,
  <NumberInput source="Count" label="獲得数" />,
]);

export const LogUserActReceiveItemList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="SlotNumber" label="スロット番号" />
    <NumField source="CharacterType" label="キャラクタータイプ" />
    <NumField source="ActItemId" label="アクトアイテムID" />
    <NumField source="Count" label="獲得数" />
  </CommonLogUserList>
);
