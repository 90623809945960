import * as React from "react";
import {
  Menu,
  DashboardMenuItem,
  MenuItemLink,
  useResourceDefinitions,
  useSidebarState,
  usePermissions,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AppsIcon from "@mui/icons-material/Apps";
import BuildIcon from "@mui/icons-material/Build";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DefaultIcon from "@mui/icons-material/ViewList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryIcon from "@mui/icons-material/History";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import WarningIcon from "@mui/icons-material/Warning";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { rolesAdmin, rolesMember, rolesReader, rolesPlanner } from "../../config/role";
import { Group } from "../../config/group";

let customRoutes = [
  {
    path: "gacha_simulator",
    label: "GachaSimulator",
    group: Group.Other,
    icon: DefaultIcon,
  },
];

if (process.env.REACT_APP_ENV !== "prod") {
  customRoutes.push({
    path: "debug",
    label: "Debug",
    group: Group.Other,
    icon: PeopleIcon,
  });
}

const groups = [
  {
    key: Group.Announcement,
    name: "お知らせ",
    icon: <AnnouncementIcon />,
    roles: rolesPlanner,
  },
  {
    key: Group.Maintenance,
    name: "メンテナンス",
    icon: <BuildIcon />,
    roles: rolesMember,
  },
  {
    key: Group.User,
    name: "ユーザー",
    icon: <PersonIcon />,
    roles: rolesReader,
  },
  {
    key: Group.Log,
    name: "ログ",
    icon: <HistoryIcon />,
    roles: rolesReader,
  },
  {
    key: Group.Kpi,
    name: "KPI関連",
    icon: <QueryStatsIcon />,
    roles: rolesPlanner,
  },
  {
    key: Group.Present,
    name: "付与",
    icon: <CardGiftcardIcon />,
    roles: rolesMember,
  },
  {
    key: Group.Ban,
    name: "BAN関連",
    icon: <WarningIcon />,
    roles: rolesMember,
  },
  {
    key: Group.Other,
    name: "その他",
    icon: <AppsIcon />,
    roles: rolesPlanner,
  },
  {
    key: Group.Manage,
    name: "管理",
    icon: <ManageAccountsIcon />,
    roles: rolesAdmin,
  },
];

const useStyles = makeStyles({
  listItemIcon: {
    minWidth: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  hiddenText: {
    display: "none",
  },
  visibleText: {
    display: "block",
  },
});

export const MyMenu = (props: any) => {
  const classes = useStyles();
  const resources = useResourceDefinitions();
  const [open] = useSidebarState();
  const { permissions } = usePermissions();

  let groupedResources: { [key: string]: any[] } = {};

  for (let name of Object.keys(resources)) {
    const resource = resources[name];
    const group = resource.options?.group || "other";
    if (!groupedResources[group]) groupedResources[group] = [];
    groupedResources[group].push(resource);
  }

  customRoutes.forEach((route) => {
    const group = route.group;
    if (!groupedResources[group]) groupedResources[group] = [];
    groupedResources[group].push(route);
  });

  return (
    <Menu {...props}>
      <DashboardMenuItem />
      {groups.map(
        (group) =>
          groupedResources[group.key] &&
          group.roles.includes(permissions?.role) && (
            <Accordion key={group.key}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {group.icon || <ExpandMoreIcon />}
                <Typography
                  variant="body2"
                  className={open ? classes.visibleText : classes.hiddenText}
                >
                  {group.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                {groupedResources[group.key].map((resource) => (
                  <MenuItemLink
                    key={resource.name || resource.path}
                    to={`/${resource.name || resource.path}`}
                    primaryText={
                      resource.options?.label ||
                      resource.label ||
                      resource.name ||
                      resource.path
                    }
                    leftIcon={
                      resource.icon ? (
                        React.createElement(resource.icon, {
                          className: classes.listItemIcon,
                        })
                      ) : (
                        <DefaultIcon className={classes.listItemIcon} />
                      )
                    }
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )
      )}
    </Menu>
  );
};
