import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList, NumField } from "../Common";

const userEquipmentCardAcquisitionFilters = [
  <NumberInput source="EquipmentCardId" label="EquipmentCardId" />,
];

export const UserEquipmentCardAcquisitionList = (props: any) => (
  <CommonList {...props} addFilters={userEquipmentCardAcquisitionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="EquipmentCardId" label="EquipmentCardId" />
    </Datagrid>
  </CommonList>
);
