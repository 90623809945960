import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList } from "../Common";

const userReceivedGlobalPresentFilters = [
  <NumberInput source="PresentId" label="プレゼントID" />,
];

export const UserReceivedGlobalPresentList = (props: any) => (
  <CommonList {...props} addFilters={userReceivedGlobalPresentFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="PresentId" label="プレゼントID" />
    </Datagrid>
  </CommonList>
);
