export const AdminApiEndpoints = () => {
  return process.env.REACT_APP_ADMIN_API_URL?.split(",");
};

export const AdminApiEndpoint = (key: number) => {
  const endpoints = AdminApiEndpoints();
  if (endpoints) {
    return endpoints[key];
  }
  return "";
};

export const DevToolApiEndpoints = () => {
  return process.env.REACT_APP_DEVTOOL_API_URL?.split(",");
};

export const DevToolApiEndpoint = (key: number) => {
  const endpoints = DevToolApiEndpoints();
  if (endpoints) {
    return endpoints[key];
  }
  return "";
};
