import { useRecordContext } from "react-admin";

export const EditTitle = (props: any) => {
  const record = useRecordContext();
  return (
    <span>
      {props.name} {record ? `"${record.id}"` : ""}
    </span>
  );
};
