import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userRankFilters = [
  <NumberInput source="Rank" label="ランク" />,
  <NumberInput source="Exp" label="EXP" />,
];

export const UserRankList = (props: any) => (
  <CommonList {...props} addFilters={userRankFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="Rank" label="ランク" />
      <NumField source="Exp" label="EXP" />
    </Datagrid>
  </CommonList>
);
