import {
  useReference,
} from "react-admin";

const masterCacheTime = 60 * 1000;

export const GetMasterData = (masterTable: string, id: number) => {
  const {
    referenceRecord: record,
    isLoading,
    error,
  } = useReference({
    reference: masterTable,
    id: id,
    options: { cacheTime: masterCacheTime },
  });
  if (isLoading) {
    return null;
  }
  if (error) {
    return null;
  }
  return record;
};
