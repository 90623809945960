import { Datagrid, TextField, TextInput } from "react-admin";
import { CommonList, YMDHMSDateField } from "../Common";

const userCommonBilledHistoryFilters = [
  <TextInput source="ProductId" label="プロダクトID" />,
];

export const UserCommonBilledHistoryList = (props: any) => (
  <CommonList {...props} addFilters={userCommonBilledHistoryFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="ProductId" label="プロダクトID" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
