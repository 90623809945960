import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="BattlePassSeasonId" label="バトルパスシーズンID" />,
  <NumberInput source="Exp" label="Exp" />,
  <NumberInput source="BeforeExp" label="ExpBefore" />,
  <NumberInput source="AfterExp" label="ExpAfter" />,
  <NumberInput source="UpLevel" label="レベルアップ数" />,
  <NumberInput source="BeforeLevel" label="レベルBefore" />,
  <NumberInput source="AfterLevel" label="レベルAfter" />,
]);

export const LogUserBattlePassExpList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="BattlePassSeasonId" label="バトルパスシーズンID" />
    <NumField source="Exp" label="Exp" />
    <NumField source="BeforeExp" label="ExpBefore" />
    <NumField source="AfterExp" label="ExpAfter" />
    <NumField source="UpLevel" label="レベルアップ数" />
    <NumField source="BeforeLevel" label="レベルBefore" />
    <NumField source="AfterLevel" label="レベルAfter" />
  </CommonLogUserList>
);
