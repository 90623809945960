import {
  Button,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FunctionField,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { EditTitle, EditToolbar } from "../Common";
import { roleNames } from "../../config/role";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import EmailIcon from "@mui/icons-material/Email";
import { CommonList } from "../Common";

const ResetPasswordButton = () => {
  const notify = useNotify();
  const record = useRecordContext();

  const handleClick = () => {
    sendPasswordResetEmail(auth, record.email)
      .then(() => {
        notify("登録されたメールアドレスに送信しました", { type: "info" });
      })
      .catch((error: any) => {
        notify(error.message, { type: "warning" });
      });
  };

  return (
    <Button label="パスワードリセット" onClick={handleClick}>
      <EmailIcon />
    </Button>
  );
};

export const AuthUserList = (props: any) => (
  <CommonList {...props} filters={null}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="displayName" label="名前" sortable={false} />
      <TextField source="email" label="メールアドレス" sortable={false} />
      <FunctionField
        label="権限"
        render={(record: any) =>
          roleNames.find((r) => r.id === record.CustomClaims?.role)?.name || ""
        }
      />
      <EditButton />
      <ResetPasswordButton />
    </Datagrid>
  </CommonList>
);

export const AuthUserEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="管理画面アカウント" />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="displayName" label="名前" />
      <TextInput source="email" label="メールアドレス" />
      <SelectInput
        source="CustomClaims.role"
        label="権限"
        choices={roleNames}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

const AuthUserCreateToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const redirect = useRedirect();

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      const data = getValues();
      const response = await dataProvider.create("AuthUser", { data });

      return sendPasswordResetEmail(auth, response.data.email);
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        redirect("/AuthUser");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={() => mutate()} disabled={isLoading} />
    </Toolbar>
  );
};

export const AuthUserCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm toolbar={<AuthUserCreateToolbar />}>
      <TextInput source="displayName" label="名前" />
      <TextInput source="email" label="メールアドレス" />
      <SelectInput
        source="CustomClaims.role"
        label="権限"
        choices={roleNames}
        validate={required()}
      />
    </SimpleForm>
  </Create>
);
