import { FirebaseAuthProvider } from "react-admin-firebase";
import { AuthProvider } from "react-admin";
import * as process from "process";
import { app, firebaseConfig } from "../firebase";
import { Role } from "../config/role";

let authProvider: AuthProvider;

if (process.env.REACT_APP_AUTH_ENABLED === "true") {
  authProvider = FirebaseAuthProvider(firebaseConfig, { app });
} else {
  authProvider = {
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    checkError: () => Promise.resolve(),
    checkAuth: () => Promise.resolve(),
    getPermissions: () => Promise.resolve({ role: Role.Admin }),
    getJWTToken: () => Promise.resolve(),
  };
}

export default authProvider;
