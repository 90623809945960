import { Datagrid, TextField, TextInput, NumberInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userTowerBattleStatusFilters = [
  <NumberInput source="TowerStageId" label="TowerStageId" />,
  <TextInput source="Status" label="ステータス" />,
  <NumberInput source="EnemyGroupId_1" label="EnemyGroupId_1" />,
  <NumberInput source="EnemyGroupId_2" label="EnemyGroupId_2" />,
  <NumberInput source="EnemyGroupId_3" label="EnemyGroupId_3" />,
  <NumberInput source="EnemyGroupId_3" label="EnemyGroupId_3" />,
  <NumberInput source="ContinueCount" label="コンティニュー回数" />,
  <NumberInput source="PartyNumber" label="パーティーNO" />,
];

export const UserTowerBattleStatusList = (props: any) => (
  <CommonList {...props} addFilters={userTowerBattleStatusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="TowerStageId" label="TowerStageId" />
      <TextField source="Status" label="ステータス" />
      <NumField source="EnemyGroupId_1" label="EnemyGroupId_1" />
      <NumField source="EnemyGroupId_2" label="EnemyGroupId_2" />
      <NumField source="EnemyGroupId_3" label="EnemyGroupId_3" />
      <NumField source="EnemyGroupId_3" label="EnemyGroupId_3" />
      <NumField source="ContinueCount" label="コンティニュー回数" />
      <NumField source="PartyNumber" label="パーティーNO" />
      <YMDHMSDateField source="BattleExpiredAt" label="バトル有効期限" />
    </Datagrid>
  </CommonList>
);
