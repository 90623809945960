import { TextInput } from "react-admin";
import { DateTimeWithSecInput } from "./Input";

export const CommonLogUserFilter = (addFilters: any) => {
  let filters = [
    <TextInput source="Id" label="ID" />,
    <TextInput source="UserId" label="ユーザーID" alwaysOn />,
    <TextInput source="LogUserActionId" label="アクションID" alwaysOn />,
  ];

  if (addFilters) {
    filters.push(...addFilters);
  }

  filters.push(
    <DateTimeWithSecInput source="CreatedAt_from" label="作成日時From" />,
    <DateTimeWithSecInput source="CreatedAt_to" label="作成日時To" />
  );

  return filters;
};
