import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Title } from "../../utils/title";

export const Dashboard = () => (
  <Card>
    <CardHeader title={Title()} />
    <CardContent>(ここにダッシュボードのコンテンツを配置予定)</CardContent>
  </Card>
);
