import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="SeasonPassId" label="シーズンパスID" />,
  <NumberInput source="SeasonPassGroupId" label="シーズンパスグループID" />,
  <NumberInput source="ReceivedCount" label="受取回数" />,
]);

export const LogUserSeasonPassLoginBonusList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="SeasonPassId" label="シーズンパスID" />
    <NumField source="SeasonPassGroupId" label="シーズンパスグループID" />
    <NumField source="ReceivedCount" label="受取回数" />
  </CommonLogUserList>
);
