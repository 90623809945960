import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FileField,
  FileInput,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { DateTimeWithSecInput, EditTitle, YMDHMSDateField } from "../Common";
import { FileInputResult, readFileAsText } from "utils/file";
import { useNavigate } from "react-router-dom";
import { Link, Dialog, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CommonList } from "../Common";

const pushNotificationListFilters = [
  <TextInput source="Id" label="Id" alwaysOn />,
];

export const PushNotificationList = (props: any) => (
  <CommonList {...props} filters={pushNotificationListFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="Id" />
      <TextField source="Title" label="Title" />
      <TextField source="Content" label="Content" />
      <TextField source="ImagePath" label="ImagePath" />
      <YMDHMSDateField source="ScheduledAt" label="ScheduledAt" />
      <BooleanField source="IsForceDelivery" label="IsForceDelivery" />
      <TextField source="DeliveryStatus" label="DeliveryStatus" />
      <BooleanField source="HasTargetUsers" label="HasTargetUsers" />
      <EditButton />
    </Datagrid>
  </CommonList>
);

const PushNotificationCreateToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      const data = getValues();
      const fileData: FileInputResult = data.TargetUsersCsv;
      if (fileData && fileData.rawFile) {
        const fileContents = await readFileAsText(fileData.rawFile);
        data.TargetUsers = fileContents
          .split(/\r\n|\n/)
          .filter((line) => line.trim() !== "")
          .map((line) => [line]);
      }
      return dataProvider.create("PushNotification", { data });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        navigate("/PushNotification");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton onClick={() => mutate()} disabled={isLoading} />
    </Toolbar>
  );
};

export const PushNotificationCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm toolbar={<PushNotificationCreateToolbar />}>
      <TextInput source="Title" />
      <TextInput source="Content" label="Content" />
      <TextInput source="ImagePath" label="ImagePath" />
      <DateTimeWithSecInput source="ScheduledAt" label="ScheduledAt" />
      <BooleanInput source="IsForceDelivery" label="IsForceDelivery" />
      <FileInput source="TargetUsersCsv" accept=".csv">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

const PushNotificationEditToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const navigate = useNavigate();
  const record = useRecordContext();

  const { mutate, isLoading } = useMutation(
    ["update"],
    async () => {
      const data = getValues();
      record.TargetUsers = {};
      const fileData: FileInputResult = data.TargetUsersCsv;
      if (fileData && fileData.rawFile) {
        const fileContents = await readFileAsText(fileData.rawFile);
        data.TargetUsers = fileContents
          .split(/\r\n|\n/)
          .filter((line) => line.trim() !== "")
          .map((line) => [line]);
      }

      return dataProvider.update("PushNotification", {
        id: record.id,
        previousData: record,
        data: data,
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        navigate("/PushNotification");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton onClick={() => mutate()} disabled={isLoading} />
      <DeleteWithConfirmButton
        confirmContent="You will not be able to recover this record. Are you sure?"
        translateOptions={{ name: record.id }}
      />
    </Toolbar>
  );
};

const useStyles = makeStyles({
  dialogContent: {
    minWidth: "300px",
    minHeight: "100px",
    padding: "20px",
    backgroundColor: "#f7f7f7",
    borderRadius: "8px",
  },
  list: {
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    marginBottom: "10px",
  },
});

const TargetUserDialog = ({ source }: any) => {
  const classes = useStyles();
  const record = useRecordContext();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (event: any) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasValues =
    record[source] &&
    Array.isArray(record[source]) &&
    record[source].length > 0;

  return (
    <div>
      {hasValues && (
        <Link component="button" variant="body2" onClick={handleClickOpen}>
          TargetUsers
        </Link>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={classes.dialogContent}>
          {hasValues && (
            <ul className={classes.list}>
              {record[source].map((item: any, index: any) => (
                <li key={index} className={classes.listItem}>
                  {item}
                </li>
              ))}
            </ul>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const PushNotificationEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="PushNotification" />}>
    <SimpleForm toolbar={<PushNotificationEditToolbar />}>
      <TextInput source="Title" />
      <TextInput source="Content" label="Content" />
      <TextInput source="ImagePath" label="ImagePath" />
      <DateTimeWithSecInput source="ScheduledAt" label="ScheduledAt" />
      <BooleanInput source="IsForceDelivery" label="IsForceDelivery" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <BooleanInput
              source="DeleteTargetUsers"
              label="DeleteTargetUsers"
              disabled={!formData.HasTargetUsers}
              {...rest}
            />
          );
        }}
      </FormDataConsumer>
      <TargetUserDialog source="RegisteredTargetUsers" />
      <FileInput source="TargetUsersCsv" accept=".csv">
        <FileField source="src" title="title" />
      </FileInput>
      <TextInput disabled source="DeliveryStatus" label="DeliveryStatus" />
    </SimpleForm>
  </Edit>
);
