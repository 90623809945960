import { TextInput, TextField } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList } from "../Common";

const filters = CommonLogUserFilter([
  <TextInput source="NewFirebaseUid" label="新しいFirebase UID" />,
  <TextInput source="OldFirebaseUid" label="古いFirebase UID" />,
]);

export const LogUserAccountMigrationRecoveryList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <TextField source="NewFirebaseUid" label="新しいFirebase UID" />
    <TextField source="OldFirebaseUid" label="古いFirebase UID" />
  </CommonLogUserList>
);
