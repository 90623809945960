import {
  Datagrid,
  TextField,
  NumberInput,
  BooleanField,
  BooleanInput,
  ReferenceField,
} from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userHonorFilters = [
  <NumberInput source="HonorId" label="HonorId" />,
  <BooleanInput source="IsNew" label="NEW" />,
];

export const UserHonorList = (props: any) => (
  <CommonList {...props} addFilters={userHonorFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="HonorId" label="HonorId" />
      <ReferenceField source="HonorId" reference="Honor" label="称号名">
        <TextField source="name" />
      </ReferenceField>
      <YMDHMSDateField source="ReceivedAt" label="受取日時" />
      <BooleanField source="IsNew" label="NEW" />
    </Datagrid>
  </CommonList>
);
