import { CommonList, YMDHMSDateField, NumField } from "components/Common";
import { Datagrid, TextField, NumberInput } from "react-admin";

const userTowerFilters = [
  <NumberInput source="TowerEventId" label="TowerEventId" />,
  <NumberInput source="Stage" label="到達ステージ" />,
  <NumberInput source="TotalScore" label="合計スコア" />,
];

export const UserTowerList = (props: any) => (
  <CommonList {...props} addFilters={userTowerFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="TowerEventId" label="TowerEventId" />
      <NumField source="Stage" label="到達ステージ" />
      <NumField source="TotalScore" label="合計スコア" />
      <YMDHMSDateField
        source="LimitedFormationChangedAt"
        label="制限編成変更日時"
      />
      <YMDHMSDateField source="RewardReceivedAt" label="スコア報酬受取日時" />
    </Datagrid>
  </CommonList>
);
