import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userPartyCharacterCardFilters = [
  <NumberInput source="PartyNumber" label="パーティーNO" />,
  <NumberInput source="CharacterCardSlotNumber" label="スロットNO" />,
  <NumberInput source="CharacterCardId" label="CharacterCardId" />,
];

export const UserPartyCharacterCardList = (props: any) => (
  <CommonList {...props} addFilters={userPartyCharacterCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="PartyNumber" label="パーティーNO" />
      <NumField source="CharacterCardSlotNumber" label="スロットNO" />
      <NumField source="CharacterCardId" label="CharacterCardId" />
    </Datagrid>
  </CommonList>
);
