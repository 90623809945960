import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput
    source="EventLoginBonusSeasonId"
    label="イベントログインボーナスシーズンID"
  />,
  <NumberInput source="ReceivedCount" label="受取回数" />,
]);

export const LogUserEventLoginBonusList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField
      source="EventLoginBonusSeasonId"
      label="イベントログインボーナスシーズンID"
    />
    <NumField source="ReceivedCount" label="受取回数" />
  </CommonLogUserList>
);
