import { defaultTheme } from "react-admin";
import { deepmerge } from "@mui/utils";

const defaultThemeColor = "2196f3";

export const Theme = deepmerge(defaultTheme, {
  palette: {
    secondary: {
      main: `#${process.env.REACT_APP_THEME_COLOR || defaultThemeColor}`,
    },
  },
});
