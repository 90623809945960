import { Datagrid, TextField, NumberInput, FunctionField } from "react-admin";
import { CommonList, NumField } from "../Common";
import { Platforms } from "../../common/Platform";

const userBilledStoneFilters = [<NumberInput source="Count" label="個数" />];

export const UserBilledStoneList = (props: any) => (
  <CommonList {...props} addFilters={userBilledStoneFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <FunctionField
        label="プラットフォーム"
        render={(r: any) => `${r.Platform}: ${Platforms[r.Platform]}`}
      />
      <NumField source="Count" label="個数" />
    </Datagrid>
  </CommonList>
);
