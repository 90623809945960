import {
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  Datagrid,
  NumberInput,
  SelectInput,
  Create,
  TextField,
  EditButton,
  SelectField,
  Edit,
  SaveButton,
  Toolbar,
  useNotify,
  useDataProvider,
  WithRecord,
  RaRecord,
} from "react-admin";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ContentTypes } from "common/ContentType";
import {
  CommonList,
  DateTimeWithSecInput,
  EditTitle,
  EditToolbar,
  YMDHMSDateField,
  NumField,
} from "components/Common";
import { Button } from "@mui/material";

const SendToUserButton = (record: RaRecord) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      return dataProvider.create("TransferPresent", {
        data: {
          present_id: record.PresentId,
          user_id: record.UserId,
          content_type: record.ContentType,
          content_id: record.ContentId,
          content_num: record.ContentNum,
          custom_message: record.CustomMessage,
          open_at: record.OpenAt,
          expired_at: record.ExpiredAt,
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        navigate("/AdminPresent");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Button onClick={() => mutate()} disabled={isLoading}>
      付与
    </Button>
  );
};

export const AdminPresentList = (props: any) => (
  <CommonList {...props} filters={null}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="Id" />
      <TextField source="UserId" label="ユーザーID" />
      <SelectField
        source="ContentType"
        label="コンテンツタイプ"
        choices={ContentTypes}
      />
      <NumField source="ContentId" label="コンテンツID" />
      <NumField source="ContentNum" label="個数" />
      <TextField source="CustomMessage" label="カスタムメッセージ" />
      <YMDHMSDateField source="OpenAt" label="付与開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="受取期限" />
      <EditButton />
      <WithRecord label="付与" render={(record) => SendToUserButton(record)} />
    </Datagrid>
  </CommonList>
);

export const AdminPresentEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="プレゼント付与" />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="PresentId" label="プレゼントID" disabled />
      <TextInput source="UserId" label="ユーザーID" disabled />
      <SelectInput
        source="ContentType"
        label="コンテンツタイプ"
        choices={ContentTypes}
      />
      <NumberInput source="ContentId" label="コンテンツID" />
      <NumberInput source="ContentNum" label="個数" />
      <TextInput source="CustomMessage" label="カスタムメッセージ" />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" />
      <DateTimeWithSecInput source="ExpiredAt" label="受取期限" />
    </SimpleForm>
  </Edit>
);

// 基本のtoolbarでは複数作成した場合のレスポンスをparse出来ないためcreateManyを利用するカスタムのツールバーを用意
const AdminPresentCreateToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    ["createMany"],
    async () => {
      return dataProvider.createMany("AdminPresent", {
        data: {
          user_ids: getValues("UserIDs"),
          content_type: getValues("ContentType"),
          content_id: getValues("ContentId"),
          content_num: getValues("ContentNum"),
          custom_message: getValues("CustomMessage"),
          open_at: getValues("OpenAt"),
          expired_at: getValues("ExpiredAt"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        navigate("/AdminPresent");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={() => mutate()} disabled={isLoading} />
    </Toolbar>
  );
};

export const AdminPresentCreate = (props: any) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm toolbar={<AdminPresentCreateToolbar />}>
        <ArrayInput source="UserIDs" label="ユーザーID">
          <SimpleFormIterator inline>
            {/*
              sourceの値を設定するとオブジェクトの配列として解釈されるため空文字で設定。
              また、要素追加時に余計な文字列が挿入されるのを防ぐためにformatを設定。
              https://github.com/marmelab/react-admin/issues/2383#issuecomment-593880920
            */}
            <TextInput
              source=""
              format={(value) => (typeof value === "object" ? "" : value)}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          source="ContentType"
          label="コンテンツタイプ"
          choices={ContentTypes}
        />
        <NumberInput source="ContentId" label="コンテンツID" />
        <NumberInput source="ContentNum" label="個数" />
        <TextInput
          source="CustomMessage"
          label="カスタムメッセージ"
          helperText="設定しない場合はデフォルトの値が利用されます"
        />
        <DateTimeWithSecInput source="OpenAt" label="付与開始日時" />
        <DateTimeWithSecInput source="ExpiredAt" label="受取期限" />
      </SimpleForm>
    </Create>
  );
};
