import { NumberInput, TextField, TextInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="Price" label="価格" />,
  <NumberInput source="Count" label="数量" />,
  <NumberInput source="UserPlatform" label="ユーザープラットフォーム" />,
  <NumberInput source="PurchasePlatform" label="購入プラットフォーム" />,
  <TextInput source="ProductId" label="プロダクトID" />,
]);

export const LogUserPurchaseList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="Price" label="価格" />
    <NumField source="Count" label="数量" />
    <NumField source="UserPlatform" label="ユーザープラットフォーム" />
    <NumField source="PurchasePlatform" label="購入プラットフォーム" />
    <TextField source="ProductId" label="プロダクトID" />
  </CommonLogUserList>
);
